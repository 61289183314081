import {
  ADD_MEASUREMENT,
  DELETE_MEASUREMENT,
  EDIT_MEASUREMENT,
  GET_MEASUREMENT_LIST,
} from "../../actions/MeasurementAction";

const initialState = {
  getMeasurementLoading: false,
  getMeasurementResult: false,
  getMeasurementError: false,

  editMeasurementLoading: false,
  editMeasurementResult: false,
  editMeasurementError: false,

  addMeasurementLoading: false,
  addMeasurementResult: false,
  addMeasurementError: false,

  deleteMeasurementLoading: false,
  deleteMeasurementResult: false,
  deleteMeasurementError: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MEASUREMENT_LIST:
      return {
        ...state,
        getMeasurementLoading: action.payload.loading,
        getMeasurementResult: action.payload.data,
        getMeasurementError: action.payload.errorMessage,
      };
    case ADD_MEASUREMENT:
      return {
        ...state,
        addMeasurementLoading: action.payload.loading,
        addMeasurementResult: action.payload.data,
        addMeasurementError: action.payload.errorMessage,
      };
    case EDIT_MEASUREMENT:
      return {
        ...state,
        editMeasurementLoading: action.payload.loading,
        editMeasurementResult: action.payload.data,
        editMeasurementError: action.payload.errorMessage,
      };
    case DELETE_MEASUREMENT:
      return {
        ...state,
        deleteMeasurementLoading: action.payload.loading,
        deleteMeasurementResult: action.payload.data,
        deleteMeasurementError: action.payload.errorMessage,
      };
    default:
      return state;
  }
}
