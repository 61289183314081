import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import swal from "sweetalert";
import { getSpecificAccountList } from "../../../actions/AccountAction";
import { getEntityList } from "../../../actions/EntityAction";
import {
  deleteFixedAsset,
  editFixedAsset,
  getFixedAssetList,
} from "../../../actions/FixedAssetAction";
import { getFixedAssetCategoryList } from "../../../actions/FixedAssetCategoryAction";
import SpinnerTable from "../../../components/spinner/SpinnerTable";
import {
  PaginationComponent,
  SearchComponent,
} from "../../../components/Table";
import EmptyData from "../../../components/Table/EmptyData";
import { checkNumberWithCommas } from "../../../utils";

class FixedAssetList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idEntity: "",
      fixedAssets: false,
      isEditing: false,
      searchText: "Cari",

      searchedText: "",
      keyword: "",

      totalData: 0,
      currentPage: 0,
      pageSize: 10,
      pagesCount: 0,
    };
  }

  componentDidMount() {
    this.props.dispatch(getEntityList());
  }

  componentDidUpdate(prevProps) {
    const {
      getFixedAssetResult,
      getFixedAssetError,
      editFixedAssetResult,
      editFixedAssetError,
      deleteFixedAssetResult,
      deleteFixedAssetError,
    } = this.props;

    if (
      getFixedAssetResult &&
      prevProps.getFixedAssetResult !== getFixedAssetResult
    ) {
      this.setState({
        fixedAssets: getFixedAssetResult.data,
        totalData: getFixedAssetResult.data.length,
        pagesCount: Math.ceil(
          getFixedAssetResult.data.length / this.state.pageSize
        ),
        isEditing: false,
      });
    }

    if (
      editFixedAssetResult &&
      prevProps.editFixedAssetResult !== editFixedAssetResult
    ) {
      this.setState({
        isEditing: false,
      });
      this.props.dispatch(getFixedAssetList(this.state.idEntity));
      swal("Sukses", editFixedAssetResult.messages, "success");
    }

    if (
      deleteFixedAssetResult &&
      prevProps.deleteFixedAssetResult !== deleteFixedAssetResult
    ) {
      this.setState({
        isEditing: false,
      });
      this.getData();
      swal("Sukses", deleteFixedAssetResult.messages, "success");
    }

    if (
      getFixedAssetError &&
      prevProps.getFixedAssetError !== getFixedAssetError
    ) {
      swal("Error", getFixedAssetError.messages.error, "error");
    }

    if (
      editFixedAssetError &&
      prevProps.editFixedAssetError !== editFixedAssetError
    ) {
      swal("Error", editFixedAssetError.messages.error, "error");
    }

    if (
      deleteFixedAssetError &&
      prevProps.deleteFixedAssetError !== deleteFixedAssetError
    ) {
      swal("Error", deleteFixedAssetError.messages.error, "error");
    }
  }

  getData = () => {
    const { idEntity } = this.state;
    if (idEntity) {
      this.props.dispatch(getFixedAssetList(idEntity));
      this.props.dispatch(getFixedAssetCategoryList());
      this.props.dispatch(getSpecificAccountList(idEntity, "fixed asset"));
    }
  };

  handleSelectChange = (event) => {
    const value = event.target.value;
    this.setState({
      [event.target.name]: value,
      searchText: "Cari",
      fixedAssets: false,
    });
  };

  addData = () => {
    const { fixedAssets, idEntity } = this.state;
    const template = {
      id: "",
      id_entity: idEntity,
      id_fixed_asset_category: "",
      id_chart_of_account: "",
      code: idEntity + moment().format("YMdHm"),
      name: "",
      economic_age: "0",
    };

    const fixedAssetsTemp = [...fixedAssets];
    fixedAssetsTemp.push(template);

    this.setState({
      fixedAssets: fixedAssetsTemp.sort((a, b) => a.code.localeCompare(b.code)),
    });
  };

  saveChanging = () => {
    const { fixedAssets, isEditing } = this.state;

    if (isEditing) this.props.dispatch(editFixedAsset(fixedAssets));
    else swal("Error", "Tidak ada data yang harus disimpan!!", "error");
  };

  handleChange = (event, index) => {
    const { fixedAssets } = this.state;
    const updatedFixedAssets = [...fixedAssets];
    updatedFixedAssets[index][event.target.name] = event.target.value;

    this.setState({
      fixedAssets: updatedFixedAssets,
      isEditing: true,
    });
  };

  deleteData = (id, index) => {
    const { isEditing, fixedAssets } = this.state;

    if (id) {
      if (isEditing)
        swal(
          "Error",
          "Data sedang diubah, simpan data terlebih dahulu!",
          "error"
        );
      else {
        this.props.dispatch(deleteFixedAsset(id));
      }
    } else {
      const deletedData = [...fixedAssets];
      deletedData.splice(index, 1);
      this.setState({ fixedAssets: deletedData });
    }
  };

  handleChangeText = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  filterHandleSubmit = (event) => {
    const { keyword } = this.state;
    event.preventDefault();
    this.setState({ searchedText: keyword });
  };

  handleClick = (e, index) => {
    e.preventDefault();
    this.setState({
      currentPage: index,
    });
  };

  render() {
    const {
      idEntity,
      searchText,
      fixedAssets,
      keyword,
      searchedText,
      currentPage,
      pageSize,
      pagesCount,
      totalData,
    } = this.state;
    const {
      getEntityLoading,
      getEntityResult,
      getFixedAssetLoading,
      deleteFixedAssetLoading,
      editFixedAssetLoading,
      getFixedAssetCategoryResult,
      getSpecificAccountResult,
      getFixedAssetCategoryLoading,
      getSpecificAccountLoading,
    } = this.props;

    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Master Aset Tetap</CardTitle>
              </CardHeader>
              <CardBody>
                <Table borderless>
                  <tbody>
                    {getEntityLoading ? (
                      <tr>
                        <td className="text-center">
                          <Spinner color="primary" />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td width="20%">
                          <Input
                            type="select"
                            name="idEntity"
                            onChange={(event) => this.handleSelectChange(event)}
                            value={idEntity}
                            options={getEntityResult.data}
                            style={{ display: "block", margin: "auto" }}
                          >
                            <option value="">--Pilih Entitas--</option>
                            {getEntityResult &&
                              getEntityResult.data.map((entity, index) => (
                                <option key={index} value={entity.id}>
                                  {entity.entity_name}
                                </option>
                              ))}
                          </Input>
                        </td>
                        <td>
                          <Button
                            color="success me-2"
                            disabled={getEntityLoading}
                            onClick={this.getData}
                          >
                            {searchText}
                          </Button>
                        </td>
                        <td className="float-end">
                          <Button
                            color="primary me-2"
                            disabled={!fixedAssets}
                            onClick={() => this.addData()}
                          >
                            Tambah
                          </Button>
                          <Button
                            color="warning me-2"
                            disabled={!fixedAssets}
                            onClick={this.saveChanging}
                          >
                            Simpan
                          </Button>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td></td>
                      <td></td>
                      <td className="float-end">
                        <br />
                        <SearchComponent
                          keyword={keyword}
                          searchedText={searchedText}
                          keywordHandleSubmit={this.filterHandleSubmit}
                          handleChange={this.handleChangeText}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th width="5%">No</th>
                      <th width="12%">Nomor Aset</th>
                      <th>Nama Aset</th>
                      <th width="13%">Kategori Aset</th>
                      <th width="10%">Umur Ekonomi</th>
                      <th width="20%">Akun Aset Tetap</th>
                      <th width="5%" className="text-center">
                        *
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!getSpecificAccountLoading &&
                    !getFixedAssetCategoryLoading &&
                    !deleteFixedAssetLoading &&
                    !editFixedAssetLoading &&
                    fixedAssets &&
                    fixedAssets.length > 0 ? (
                      fixedAssets
                        .filter((asset) =>
                          asset.code
                            .toLowerCase()
                            .includes(searchedText.toLowerCase()) ||
                          asset.name
                            .toLowerCase()
                            .includes(searchedText.toLowerCase())
                            ? true
                            : false
                        )
                        .slice(
                          currentPage * pageSize,
                          (currentPage + 1) * pageSize
                        )
                        .map((asset, index) => (
                          <tr key={index}>
                            <td className="align-middle">
                              {currentPage * pageSize + index + 1}
                            </td>
                            <td>
                              <Input
                                type="text"
                                value={asset.code}
                                name="code"
                                autoComplete="off"
                                onChange={(event) =>
                                  this.handleChange(event, index)
                                }
                                disabled
                              />
                            </td>
                            <td>
                              <Input
                                type="text"
                                value={asset.name}
                                name="name"
                                autoComplete="off"
                                onChange={(event) =>
                                  this.handleChange(event, index)
                                }
                              />
                            </td>

                            <td>
                              <Input
                                type="select"
                                name="id_fixed_asset_category"
                                onChange={(event) =>
                                  this.handleChange(event, index)
                                }
                                value={asset.id_fixed_asset_category}
                                options={getFixedAssetCategoryResult.data}
                                style={{ display: "block", margin: "auto" }}
                              >
                                <option value="">--Pilih Kategori--</option>
                                {getFixedAssetCategoryResult &&
                                  getFixedAssetCategoryResult.data.map(
                                    (assetCategory, index) => (
                                      <option
                                        key={index}
                                        value={assetCategory.id}
                                      >
                                        {assetCategory.name}
                                      </option>
                                    )
                                  )}
                              </Input>
                            </td>
                            <td>
                              <Input
                                type="text"
                                value={checkNumberWithCommas(
                                  asset.id_fixed_asset_category === "1"
                                    ? "0"
                                    : asset.economic_age
                                )}
                                name="economic_age"
                                autoComplete="off"
                                onChange={(event) =>
                                  this.handleChange(event, index)
                                }
                                disabled={
                                  asset.id_fixed_asset_category === "1"
                                    ? true
                                    : false
                                }
                              />
                            </td>
                            <td>
                              <Input
                                type="select"
                                name="id_chart_of_account"
                                onChange={(event) =>
                                  this.handleChange(event, index)
                                }
                                value={asset.id_chart_of_account}
                                options={getSpecificAccountResult.data}
                                style={{ display: "block", margin: "auto" }}
                              >
                                <option value="">--Pilih Akun--</option>
                                {getSpecificAccountResult &&
                                  getSpecificAccountResult.data.map(
                                    (assetAccount, index) => (
                                      <option
                                        key={index}
                                        value={assetAccount.id}
                                      >
                                        {assetAccount.name}
                                      </option>
                                    )
                                  )}
                              </Input>
                            </td>
                            <td className="text-center">
                              <Button
                                color="danger"
                                onClick={() => this.deleteData(asset.id, index)}
                              >
                                X
                              </Button>
                            </td>
                          </tr>
                        ))
                    ) : getSpecificAccountLoading ||
                      getFixedAssetCategoryLoading ||
                      getFixedAssetLoading ||
                      editFixedAssetLoading ||
                      deleteFixedAssetLoading ? (
                      <SpinnerTable colSpan={7} />
                    ) : (
                      <EmptyData colSpan={7} />
                    )}
                  </tbody>
                </Table>
                {!searchedText && (
                  <>
                    <br />
                    <PaginationComponent
                      currentPage={currentPage}
                      pagesCount={pagesCount}
                      totalData={totalData}
                      pageSize={pageSize}
                      handleClick={this.handleClick}
                    />
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  getEntityLoading: state.EntityReducer.getEntityLoading,
  getEntityResult: state.EntityReducer.getEntityResult,
  getEntityError: state.EntityReducer.getEntityError,

  getFixedAssetLoading: state.FixedAssetReducer.getFixedAssetLoading,
  getFixedAssetResult: state.FixedAssetReducer.getFixedAssetResult,
  getFixedAssetError: state.FixedAssetReducer.getFixedAssetError,

  getFixedAssetCategoryLoading:
    state.FixedAssetCategoryReducer.getFixedAssetCategoryLoading,
  getFixedAssetCategoryResult:
    state.FixedAssetCategoryReducer.getFixedAssetCategoryResult,
  getFixedAssetCategoryError:
    state.FixedAssetCategoryReducer.getFixedAssetCategoryError,

  getSpecificAccountLoading: state.AccountReducer.getSpecificAccountLoading,
  getSpecificAccountResult: state.AccountReducer.getSpecificAccountResult,
  getSpecificAccountError: state.AccountReducer.getSpecificAccountError,

  editFixedAssetLoading: state.FixedAssetReducer.editFixedAssetLoading,
  editFixedAssetResult: state.FixedAssetReducer.editFixedAssetResult,
  editFixedAssetError: state.FixedAssetReducer.editFixedAssetError,

  deleteFixedAssetLoading: state.FixedAssetReducer.deleteFixedAssetLoading,
  deleteFixedAssetResult: state.FixedAssetReducer.deleteFixedAssetResult,
  deleteFixedAssetError: state.FixedAssetReducer.deleteFixedAssetError,
});

export default connect(mapStateToProps, null)(FixedAssetList);
