import { Component, useEffect, useState } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Login from "./pages/login";
import ProtectedRoute from "./routes/ProtectedRoute";
import NotFound from "./pages/notFound";
import "./App.css";
import Dashboard from "./pages/private/Dashboard";
import { UserList, AddUser, EditUser } from "./pages/private/user";
import { AddEntity, EditEntity, EntityList } from "./pages/private/entity";
import RuleList from "./pages/private/rule/RuleList";
import AccountList from "./pages/private/account/AccountList";
import LedgerList from "./pages/private/ledger/LedgerList";
import IncomeStatementList from "./pages/private/incomeStatement/IncomeStatementList";
import CashAccountList from "./pages/private/cashAccount/CashAccountList";
import CashBook from "./pages/private/cashAccount/CashBook";
import InventoryCategoryList from "./pages/private/inventory/InventoryCategoryList";
import MeasurementList from "./pages/private/inventory/MeasurementList";
import InventoryList from "./pages/private/inventory/InventoryList";
import InventoryMutation from "./pages/private/inventory/InventoryMutation";
import Home from "./pages/private/Home";
import InventoryBalance from "./pages/private/inventory/InventoryBalance";
import FixedAssetList from "./pages/private/fixedAsset/FixedAssetList";
import FixedAssetAcquisitionList from "./pages/private/fixedAsset/FixedAssetAcquisitionList";
import FixedAssetDispositionList from "./pages/private/fixedAsset/FixedAssetDispositionList";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Dashboard {...this.props} />
            </ProtectedRoute>
          }
        >
          <Route exact path="/" element={<Home {...this.props} />} />
          <Route exact path="/home" element={<Home {...this.props} />} />
          <Route exact path="/user" element={<UserList {...this.props} />} />
          <Route exact path="/user/add" element={<AddUser {...this.props} />} />

          <Route
            exact
            path="/user/:id"
            element={<EditUser {...this.props} />}
          />
          <Route
            exact
            path="/entity"
            element={<EntityList {...this.props} />}
          />
          <Route
            exact
            path="/entity/add"
            element={<AddEntity {...this.props} />}
          />
          <Route
            exact
            path="/entity/:id"
            element={<EditEntity {...this.props} />}
          />
          <Route exact path="/rule" element={<RuleList {...this.props} />} />
          <Route
            exact
            path="/account"
            element={<AccountList {...this.props} />}
          />
          <Route
            exact
            path="/ledger"
            element={<LedgerList {...this.props} />}
          />
          <Route
            exact
            path="/income-statement"
            element={<IncomeStatementList {...this.props} />}
          />
          <Route
            exact
            path="/cash-account"
            element={<CashAccountList {...this.props} />}
          />
          <Route
            exact
            path="/cash-book"
            element={<CashBook {...this.props} />}
          />
          <Route
            exact
            path="/inventory-category"
            element={<InventoryCategoryList {...this.props} />}
          />
          <Route
            exact
            path="/measurement"
            element={<MeasurementList {...this.props} />}
          />
          <Route
            exact
            path="/inventory"
            element={<InventoryList {...this.props} />}
          />
          <Route
            exact
            path="/inventory-mutation"
            element={<InventoryMutation {...this.props} />}
          />
          <Route
            exact
            path="/inventory-balance"
            element={<InventoryBalance {...this.props} />}
          />
          <Route
            exact
            path="/fixed-asset"
            element={<FixedAssetList {...this.props} />}
          />
          <Route
            exact
            path="/fixed-asset-acquisition"
            element={<FixedAssetAcquisitionList {...this.props} />}
          />
          <Route
            exact
            path="/fixed-asset-disposition"
            element={<FixedAssetDispositionList {...this.props} />}
          />
        </Route>
        <Route path="/login" element={<Login {...this.props} />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }
}

export function APPWithRouter(props) {
  const navigate = useNavigate();
  const [hideNav, setHideNave] = useState(false);

  const resize = () => {
    let currentHideNav = window.innerWidth <= 800;
    if (currentHideNav !== hideNav) {
      setHideNave(currentHideNav);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", resize.bind(this));
    resize();

    // returned function will be called on component unmount
    return () => {
      window.removeEventListener("resize", resize.bind(this));
    };
  });

  return <App {...props} navigate={navigate} hideNav={hideNav}></App>;
}

export default App;
