import {
  DELETE_INVENTORY,
  EDIT_INVENTORY,
  GET_INVENTORY_LIST,
} from "../../actions/InventoryAction";

const initialState = {
  getInventoryLoading: false,
  getInventoryResult: false,
  getInventoryError: false,

  editInventoryLoading: false,
  editInventoryResult: false,
  editInventoryError: false,

  deleteInventoryLoading: false,
  deleteInventoryResult: false,
  deleteInventoryError: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_INVENTORY_LIST:
      return {
        ...state,
        getInventoryLoading: action.payload.loading,
        getInventoryResult: action.payload.data,
        getInventoryError: action.payload.errorMessage,
      };
    case EDIT_INVENTORY:
      return {
        ...state,
        editInventoryLoading: action.payload.loading,
        editInventoryResult: action.payload.data,
        editInventoryError: action.payload.errorMessage,
      };
    case DELETE_INVENTORY:
      return {
        ...state,
        deleteInventoryLoading: action.payload.loading,
        deleteInventoryResult: action.payload.data,
        deleteInventoryError: action.payload.errorMessage,
      };
    default:
      return state;
  }
}
