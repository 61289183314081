import {
  DELETE_FIXED_ASSET,
  EDIT_FIXED_ASSET,
  GET_FIXED_ASSET_LIST,
  GET_ACQUISITION_FIXED_ASSET_LIST,
  ADD_ACQUISITION_FIXED_ASSET,
  GET_DISPOSITION_FIXED_ASSET_LIST,
  ADD_DISPOSITION_FIXED_ASSET,
} from "../../actions/FixedAssetAction";

const initialState = {
  getFixedAssetLoading: false,
  getFixedAssetResult: false,
  getFixedAssetError: false,

  getAcquisitionFixedAssetLoading: false,
  getAcquisitionFixedAssetResult: false,
  getAcquisitionFixedAssetError: false,

  getDispositionFixedAssetLoading: false,
  getDispositionFixedAssetResult: false,
  getDispositionFixedAssetError: false,

  addAcquisitionFixedAssetLoading: false,
  addAcquisitionFixedAssetResult: false,
  addAcquisitionFixedAssetError: false,

  addDispositionFixedAssetLoading: false,
  addDispositionFixedAssetResult: false,
  addDispositionFixedAssetError: false,

  editFixedAssetLoading: false,
  editFixedAssetResult: false,
  editFixedAssetError: false,

  deleteFixedAssetLoading: false,
  deleteFixedAssetResult: false,
  deleteFixedAssetError: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FIXED_ASSET_LIST:
      return {
        ...state,
        getFixedAssetLoading: action.payload.loading,
        getFixedAssetResult: action.payload.data,
        getFixedAssetError: action.payload.errorMessage,
      };
    case GET_ACQUISITION_FIXED_ASSET_LIST:
      return {
        ...state,
        getAcquisitionFixedAssetLoading: action.payload.loading,
        getAcquisitionFixedAssetResult: action.payload.data,
        getAcquisitionFixedAssetError: action.payload.errorMessage,
      };
    case GET_DISPOSITION_FIXED_ASSET_LIST:
      return {
        ...state,
        getDispositionFixedAssetLoading: action.payload.loading,
        getDispositionFixedAssetResult: action.payload.data,
        getDispositionFixedAssetError: action.payload.errorMessage,
      };
    case ADD_ACQUISITION_FIXED_ASSET:
      return {
        ...state,
        addAcquisitionFixedAssetLoading: action.payload.loading,
        addAcquisitionFixedAssetResult: action.payload.data,
        addAcquisitionFixedAssetError: action.payload.errorMessage,
      };

    case ADD_DISPOSITION_FIXED_ASSET:
      return {
        ...state,
        addDispositionFixedAssetLoading: action.payload.loading,
        addDispositionFixedAssetResult: action.payload.data,
        addDispositionFixedAssetError: action.payload.errorMessage,
      };

    case EDIT_FIXED_ASSET:
      return {
        ...state,
        editFixedAssetLoading: action.payload.loading,
        editFixedAssetResult: action.payload.data,
        editFixedAssetError: action.payload.errorMessage,
      };
    case DELETE_FIXED_ASSET:
      return {
        ...state,
        deleteFixedAssetLoading: action.payload.loading,
        deleteFixedAssetResult: action.payload.data,
        deleteFixedAssetError: action.payload.errorMessage,
      };
    default:
      return state;
  }
}
