import { combineReducers } from "redux";
import UserReducer from "./user";
import RuleReducer from "./rule";
import AuthReducer from "./auth";
import EntityReducer from "./entity";
import AccountReducer from "./account";
import LedgerReducer from "./ledger";
import IncomeStatementReducer from "./incomeStatement";
import AccountGroupReducer from "./accountGroup";
import CashAccountReducer from "./cashAccount";
import TransactionReducer from "./transaction";
import InventoryCategoryReducer from "./inventoryCategory";
import InventoryReducer from "./inventory";
import InventoryMutationReducer from "./inventoryMutation";
import MeasurementReducer from "./measurement";
import FixedAssetCategoryReducer from "./fixedAssetCategory";
import FixedAssetReducer from "./fixedAsset";

export default combineReducers({
  UserReducer,
  AuthReducer,
  EntityReducer,
  RuleReducer,
  AccountReducer,
  AccountGroupReducer,
  LedgerReducer,
  IncomeStatementReducer,
  CashAccountReducer,
  TransactionReducer,
  InventoryCategoryReducer,
  MeasurementReducer,
  InventoryReducer,
  InventoryMutationReducer,
  FixedAssetCategoryReducer,
  FixedAssetReducer,
});
