import {
  ADD_ACCOUNT,
  DELETE_ACCOUNT,
  EDIT_ACCOUNT,
  GET_ACCOUNT_LIST,
  GET_CASH_IN_ACCOUNT_LIST,
  GET_CASH_OUT_ACCOUNT_LIST,
  GET_SPECIFIC_ACCOUNT_LIST,
} from "../../actions/AccountAction";

const initialState = {
  getCashInAccountLoading: false,
  getCashInAccountResult: false,
  getCashInAccountError: false,

  getCashOutAccountLoading: false,
  getCashOutAccountResult: false,
  getCashOutAccountError: false,

  getSpecificAccountLoading: false,
  getSpecificAccountResult: false,
  getSpecificAccountError: false,

  getAccountLoading: false,
  getAccountResult: false,
  getAccountError: false,

  editAccountLoading: false,
  editAccountResult: false,
  editAccountError: false,

  addAccountLoading: false,
  addAccountResult: false,
  addAccountError: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_ACCOUNT_LIST:
      return {
        ...state,
        getAccountLoading: action.payload.loading,
        getAccountResult: action.payload.data,
        getAccountError: action.payload.errorMessage,
      };
    case GET_SPECIFIC_ACCOUNT_LIST:
      return {
        ...state,
        getSpecificAccountLoading: action.payload.loading,
        getSpecificAccountResult: action.payload.data,
        getSpecificAccountError: action.payload.errorMessage,
      };
    case GET_CASH_IN_ACCOUNT_LIST:
      return {
        ...state,
        getCashInAccountLoading: action.payload.loading,
        getCashInAccountResult: action.payload.data,
        getCashInAccountError: action.payload.errorMessage,
      };
    case GET_CASH_OUT_ACCOUNT_LIST:
      return {
        ...state,
        getCashOutAccountLoading: action.payload.loading,
        getCashOutAccountResult: action.payload.data,
        getCashOutAccountError: action.payload.errorMessage,
      };
    case ADD_ACCOUNT:
      return {
        ...state,
        addAccountLoading: action.payload.loading,
        addAccountResult: action.payload.data,
        addAccountError: action.payload.errorMessage,
      };
    case EDIT_ACCOUNT:
      return {
        ...state,
        editAccountLoading: action.payload.loading,
        editAccountResult: action.payload.data,
        editAccountError: action.payload.errorMessage,
      };
    case DELETE_ACCOUNT:
      return {
        ...state,
        deleteAccountLoading: action.payload.loading,
        deleteAccountResult: action.payload.data,
        deleteAccountError: action.payload.errorMessage,
      };
    default:
      return state;
  }
}
