import validator from "validator";
export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

export const convertToNumber = (x) => {
  return parseInt(x.toString().replace(/\./g, ""));
};

export const checkNumberWithCommas = (x) => {
  let string = x.replace(/\./g, "");
  return validator.isInt(string)
    ? string
        .toString()
        .replace(/\./g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    : 0;
};

export const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};
