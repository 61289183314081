import { GET_FIXED_ASSET_CATEGORY_LIST } from "../../actions/FixedAssetCategoryAction";

const initialState = {
  getFixedAssetCategoryLoading: false,
  getFixedAssetCategoryResult: false,
  getFixedAssetCategoryError: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_FIXED_ASSET_CATEGORY_LIST:
      return {
        ...state,
        getFixedAssetCategoryLoading: action.payload.loading,
        getFixedAssetCategoryResult: action.payload.data,
        getFixedAssetCategoryError: action.payload.errorMessage,
      };
    default:
      return state;
  }
}
