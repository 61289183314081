import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import swal from "sweetalert";
import { getEntityList } from "../../../actions/EntityAction";
import {
  addAcquisitionFixedAsset,
  addDepreciationFixedAsset,
  getAcquisitionFixedAssetList,
  getFixedAssetList,
} from "../../../actions/FixedAssetAction";
import { AddAquisitionModal } from "../../../components/private/fixedAsset/AddAquisitionModal";
import { AddDepreciationModal } from "../../../components/private/fixedAsset/AddDepreciationModal";
import SpinnerTable from "../../../components/spinner/SpinnerTable";
import {
  PaginationComponent,
  SearchComponent,
} from "../../../components/Table";
import EmptyData from "../../../components/Table/EmptyData";
import { checkNumberWithCommas } from "../../../utils";

class FixedAssetAcquisitionList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      depreciationMonth: "1",
      depreciationYear: moment().format("YYYY"),
      idEntity: "",
      fixedAssets: false,
      showAddAqcuisitionModal: false,
      showAddDepreciationModal: false,
      searchText: "Cari",
      selectedFixedAsset: {
        id: 0,
        acquisitionDate: "",
        acquisitionValue: "",
      },

      searchedText: "",
      keyword: "",

      totalData: 0,
      currentPage: 0,
      pageSize: 10,
      pagesCount: 0,
    };
  }

  componentDidMount() {
    this.props.dispatch(getEntityList());
  }

  componentDidUpdate(prevProps) {
    const {
      getFixedAssetResult,
      getFixedAssetError,
      addAcquisitionFixedAssetResult,
      addAcquisitionFixedAssetError,
      getAcquisitionFixedAssetError,
    } = this.props;

    if (
      getFixedAssetResult &&
      prevProps.getFixedAssetResult !== getFixedAssetResult
    ) {
      this.setState({
        fixedAssets: getFixedAssetResult.data,
        selectedFixedAsset: false,
        showAddAqcuisitionModal: false,
        showAddDepreciationModal: false,
        totalData: getFixedAssetResult.data.length,
        pagesCount: Math.ceil(
          getFixedAssetResult.data.length / this.state.pageSize
        ),
      });
    }

    if (
      addAcquisitionFixedAssetResult &&
      prevProps.addAcquisitionFixedAssetResult !==
        addAcquisitionFixedAssetResult
    ) {
      swal("Sukses", addAcquisitionFixedAssetResult.messages, "success");
      this.getData();
    }

    if (
      getFixedAssetError &&
      prevProps.getFixedAssetError !== getFixedAssetError
    ) {
      swal("Error", getFixedAssetError.messages.error, "error");
    }

    if (
      getAcquisitionFixedAssetError &&
      prevProps.getAcquisitionFixedAssetError !== getAcquisitionFixedAssetError
    ) {
      swal("Error", getAcquisitionFixedAssetError.messages.error, "error");
    }

    if (
      addAcquisitionFixedAssetError &&
      prevProps.addAcquisitionFixedAssetError !== addAcquisitionFixedAssetError
    ) {
      swal("Error", addAcquisitionFixedAssetError.messages.error, "error");
    }
  }

  filterHandleSubmit = (event) => {
    const { keyword } = this.state;
    event.preventDefault();
    this.setState({ searchedText: keyword });
  };

  handleClick = (e, index) => {
    e.preventDefault();
    this.setState({
      currentPage: index,
    });
  };

  handleSelectChange = (event) => {
    const value = event.target.value;
    this.setState({
      [event.target.name]: value,
      searchText: "Cari",
      fixedAssets: false,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleChangeSelectedFixedAsset = (event) => {
    const { selectedFixedAsset } = this.state;
    const { getAcquisitionFixedAssetResult } = this.props;

    if (event.target.name === "id") {
      const selectedAsset = getAcquisitionFixedAssetResult.data.filter(
        (asset) => asset.id === event.target.value
      );

      this.setState({
        selectedFixedAsset: {
          ...selectedFixedAsset,
          id: selectedAsset.length === 0 ? "" : selectedAsset[0].id,
          name: selectedAsset.length === 0 ? "" : selectedAsset[0].name,
          economicAge:
            selectedAsset.length === 0 ? "" : selectedAsset[0].economic_age,
        },
      });
    } else {
      this.setState({
        selectedFixedAsset: {
          ...selectedFixedAsset,
          [event.target.name]: event.target.value,
        },
      });
    }
  };

  handleSubmit = () => {
    const { selectedFixedAsset } = this.state;
    if (
      selectedFixedAsset.id &&
      selectedFixedAsset.acquisitionDate &&
      selectedFixedAsset.acquisitionValue &&
      parseInt(selectedFixedAsset.id) > 0 &&
      parseInt(selectedFixedAsset.acquisitionValue) > 0
    ) {
      this.props.dispatch(addAcquisitionFixedAsset(selectedFixedAsset));
    } else swal("Error", "Isi data akuisisi dengan lengkap", "error");
  };

  handleDepreciationSubmit = () => {
    const { id, depreciationMonth, depreciationYear } = this.state;
    this.props.dispatch(
      addDepreciationFixedAsset({ id, depreciationMonth, depreciationYear })
    );
  };

  getData = () => {
    const { idEntity } = this.state;
    if (idEntity) {
      this.props.dispatch(getFixedAssetList(idEntity, "true"));
      this.props.dispatch(getAcquisitionFixedAssetList(idEntity));
    }
  };

  render() {
    const {
      idEntity,
      searchText,
      fixedAssets,
      keyword,
      searchedText,
      currentPage,
      pageSize,
      pagesCount,
      totalData,
    } = this.state;
    const { getEntityLoading, getEntityResult, getFixedAssetLoading } =
      this.props;
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Akuisisi Aset Tetap</CardTitle>
              </CardHeader>
              <CardBody>
                <Table borderless>
                  <tbody>
                    {getEntityLoading ? (
                      <tr>
                        <td className="text-center">
                          <Spinner color="primary" />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td width="20%">
                          <Input
                            type="select"
                            name="idEntity"
                            onChange={(event) => this.handleSelectChange(event)}
                            value={idEntity}
                            options={getEntityResult.data}
                            style={{ display: "block", margin: "auto" }}
                          >
                            <option value="">--Pilih Entitas--</option>
                            {getEntityResult &&
                              getEntityResult.data.map((entity, index) => (
                                <option key={index} value={entity.id}>
                                  {entity.entity_name}
                                </option>
                              ))}
                          </Input>
                        </td>
                        <td>
                          <Button
                            color="success me-2"
                            disabled={getEntityLoading}
                            onClick={this.getData}
                          >
                            {searchText}
                          </Button>
                        </td>
                        <td className="float-end">
                          <Button
                            color="primary me-2"
                            disabled={!fixedAssets}
                            onClick={() =>
                              this.setState({ showAddAqcuisitionModal: true })
                            }
                          >
                            Tambah
                          </Button>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td></td>
                      <td></td>
                      <td className="float-end">
                        <br />
                        <SearchComponent
                          keyword={keyword}
                          searchedText={searchedText}
                          keywordHandleSubmit={this.filterHandleSubmit}
                          handleChange={this.handleChangeText}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th width="5%">No</th>
                      <th width="25%">Data Aset</th>
                      <th width="25%">Akuisisi</th>
                      <th width="25%">Depresiasi</th>
                      <th width="20%" className="text-center">
                        *
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {fixedAssets && fixedAssets.length > 0 ? (
                      fixedAssets
                        .filter((asset) =>
                          asset.code
                            .toLowerCase()
                            .includes(searchedText.toLowerCase()) ||
                          asset.name
                            .toLowerCase()
                            .includes(searchedText.toLowerCase())
                            ? true
                            : false
                        )
                        .slice(
                          currentPage * pageSize,
                          (currentPage + 1) * pageSize
                        )
                        .map((asset, index) => (
                          <tr key={index}>
                            <td>{currentPage * pageSize + index + 1}</td>
                            <td>
                              <Table borderless>
                                <tbody>
                                  <tr>
                                    <td width="20%">Nomor</td>
                                    <td width="1%">:</td>
                                    <td>{asset.code}</td>
                                  </tr>
                                  <tr>
                                    <td>Nama</td>
                                    <td>:</td>
                                    <td>{asset.name}</td>
                                  </tr>
                                  <tr>
                                    <td>Umur Ekonomi</td>
                                    <td>:</td>
                                    <td>{asset.economic_age + " Bulan"} </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </td>
                            <td>
                              <Table borderless>
                                <tbody>
                                  <tr>
                                    <td width="15%">Tanggal</td>
                                    <td width="1%">:</td>
                                    <td>
                                      {moment(asset.acquisition_date).format(
                                        "DD MMMM YYYY"
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Nilai</td>
                                    <td>:</td>
                                    <td>
                                      Rp.
                                      {" " +
                                        checkNumberWithCommas(
                                          asset.acquisition_value
                                        )}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </td>
                            <td>
                              <Table borderless>
                                <tbody>
                                  <tr>
                                    <td width="15%">Tanggal</td>
                                    <td width="1%">:</td>
                                    <td>
                                      {asset.depreciation_date
                                        ? moment(
                                            asset.depreciation_date
                                          ).format("MMMM YYYY")
                                        : "-"}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </td>
                            <td className="text-center">
                              {!asset.disposition_date && (
                                <Button
                                  size="sm"
                                  color="warning"
                                  className="me-2 mb-2"
                                  onClick={() =>
                                    this.setState({
                                      id: asset.id,
                                      showAddDepreciationModal: true,
                                    })
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faPencil}
                                    className="me-2"
                                  />
                                  Depresiasi
                                </Button>
                              )}
                            </td>
                          </tr>
                        ))
                    ) : getFixedAssetLoading ? (
                      <SpinnerTable colSpan={7} />
                    ) : (
                      <EmptyData colSpan={7} />
                    )}
                  </tbody>
                </Table>
                {!searchedText && (
                  <>
                    <br />
                    <PaginationComponent
                      currentPage={currentPage}
                      pagesCount={pagesCount}
                      totalData={totalData}
                      pageSize={pageSize}
                      handleClick={this.handleClick}
                    />
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        <AddAquisitionModal
          modalToggle={this.state.showAddAqcuisitionModal}
          handleClose={() => this.setState({ showAddAqcuisitionModal: false })}
          handleChange={this.handleChangeSelectedFixedAsset}
          selectedFixedAsset={this.state.selectedFixedAsset}
          handleSubmit={this.handleSubmit}
        />
        <AddDepreciationModal
          modalToggle={this.state.showAddDepreciationModal}
          handleClose={() => this.setState({ showAddDepreciationModal: false })}
          handleChange={this.handleChange}
          handleSubmit={this.handleDepreciationSubmit}
          depreciationMonth={this.state.depreciationMonth}
          depreciationYear={this.state.depreciationYear}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  getEntityLoading: state.EntityReducer.getEntityLoading,
  getEntityResult: state.EntityReducer.getEntityResult,
  getEntityError: state.EntityReducer.getEntityError,

  getFixedAssetLoading: state.FixedAssetReducer.getFixedAssetLoading,
  getFixedAssetResult: state.FixedAssetReducer.getFixedAssetResult,
  getFixedAssetError: state.FixedAssetReducer.getFixedAssetError,

  getAcquisitionFixedAssetLoading:
    state.FixedAssetReducer.getAcquisitionFixedAssetLoading,
  getAcquisitionFixedAssetResult:
    state.FixedAssetReducer.getAcquisitionFixedAssetResult,
  getAcquisitionFixedAssetError:
    state.FixedAssetReducer.getAcquisitionFixedAssetError,

  addAcquisitionFixedAssetLoading:
    state.FixedAssetReducer.addAcquisitionFixedAssetLoading,
  addAcquisitionFixedAssetResult:
    state.FixedAssetReducer.addAcquisitionFixedAssetResult,
  addAcquisitionFixedAssetError:
    state.FixedAssetReducer.addAcquisitionFixedAssetError,
});

export default connect(mapStateToProps, null)(FixedAssetAcquisitionList);
