import {
  ADD_INVENTORY_CATEGORY,
  DELETE_INVENTORY_CATEGORY,
  EDIT_INVENTORY_CATEGORY,
  GET_INVENTORY_CATEGORY_LIST,
} from "../../actions/InventoryCategoryAction";

const initialState = {
  getInventoryCategoryLoading: false,
  getInventoryCategoryResult: false,
  getInventoryCategoryError: false,

  editInventoryCategoryLoading: false,
  editInventoryCategoryResult: false,
  editInventoryCategoryError: false,

  addInventoryCategoryLoading: false,
  addInventoryCategoryResult: false,
  addInventoryCategoryError: false,

  deleteInventoryCategoryLoading: false,
  deleteInventoryCategoryResult: false,
  deleteInventoryCategoryError: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_INVENTORY_CATEGORY_LIST:
      return {
        ...state,
        getInventoryCategoryLoading: action.payload.loading,
        getInventoryCategoryResult: action.payload.data,
        getInventoryCategoryError: action.payload.errorMessage,
      };
    case ADD_INVENTORY_CATEGORY:
      return {
        ...state,
        addInventoryCategoryLoading: action.payload.loading,
        addInventoryCategoryResult: action.payload.data,
        addInventoryCategoryError: action.payload.errorMessage,
      };
    case EDIT_INVENTORY_CATEGORY:
      return {
        ...state,
        editInventoryCategoryLoading: action.payload.loading,
        editInventoryCategoryResult: action.payload.data,
        editInventoryCategoryError: action.payload.errorMessage,
      };
    case DELETE_INVENTORY_CATEGORY:
      return {
        ...state,
        deleteInventoryCategoryLoading: action.payload.loading,
        deleteInventoryCategoryResult: action.payload.data,
        deleteInventoryCategoryError: action.payload.errorMessage,
      };
    default:
      return state;
  }
}
