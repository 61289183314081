import {
  dispatchError,
  dispatchLoading,
  dispatchSuccess,
  getAuthorizationHeader,
} from "../utils";
import axios from "axios";

export const GET_USER_LIST = "GET_USER_LIST";
export const GET_DETAIL_USER = "GET_DETAIL_USER";
export const ADD_USER = "ADD_USER";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";

export const getUserList = (searchText = "") => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_USER_LIST);
    axios({
      method: "GET",
      url: "users?searchText=" + searchText,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, GET_USER_LIST, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, GET_USER_LIST, error.response.data);
      });
  };
};

export const getDetailUser = (id) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_DETAIL_USER);
    axios({
      method: "GET",
      url: "users?id=" + id,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, GET_DETAIL_USER, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, GET_DETAIL_USER, error.response.data);
      });
  };
};

export const addUser = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, ADD_USER);

    axios({
      method: "POST",
      url: "add-user",
      timeout: 120000,
      headers: getAuthorizationHeader(),
      data,
    })
      .then((response) => {
        dispatchSuccess(dispatch, ADD_USER, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, ADD_USER, error.response.data);
      });
  };
};

export const editUser = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, EDIT_USER);
    axios({
      method: "PUT",
      url: "update-user",
      timeout: 120000,
      headers: getAuthorizationHeader(),
      data,
    })
      .then((response) => {
        dispatchSuccess(dispatch, EDIT_USER, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, EDIT_USER, error.response.data);
      });
  };
};

export const deleteUser = (id) => {
  return (dispatch) => {
    dispatchLoading(dispatch, DELETE_USER);
    axios({
      method: "DELETE",
      url: "users/" + id,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, DELETE_USER, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, DELETE_USER, error.response.data);
      });
  };
};
