import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faArrowLeft,
  faUserCog,
  faMoneyBill,
  faMoneyCheckDollar,
  faSuitcase,
  faBuilding,
} from "@fortawesome/free-solid-svg-icons";
import { NavItem, NavLink, Nav, Label } from "reactstrap";
import classNames from "classnames";
import { Link } from "react-router-dom";
import SubMenu from "./SubMenu";

const styleNavLink = { color: "#E4E3E3", fontSize: 17 };

export default function SideBar({ isOpen, toggle, hideNav }) {
  const toggleDependHideNav = () => {
    if (hideNav) return toggle();
  };

  return (
    <>
      <div className={classNames("sidebar", { "is-open": isOpen })}>
        <div className="side-menu">
          <Nav vertical className="list-unstyled pb-3">
            <Label
              tag="h3"
              style={{ color: "#E4E3E3" }}
              className="ms-3 mt-5 mb-3"
            >
              <span color="info" onClick={toggle}>
                <FontAwesomeIcon icon={faArrowLeft} className="me-2" />{" "}
                SIAB_CARD
              </span>
            </Label>

            <NavItem className="mb-2">
              <NavLink
                tag={Link}
                to={"/home"}
                style={styleNavLink}
                onClick={toggleDependHideNav}
              >
                <FontAwesomeIcon icon={faHome} className="me-2  ms-3" />
                Home
              </NavLink>
            </NavItem>
            <SubMenu
              title="Modul Kas"
              styleNavLink={styleNavLink}
              icon={faMoneyCheckDollar}
              items={submenus[2]}
              toggleSidebar={toggleDependHideNav}
            />
            <SubMenu
              title="Modul Persediaan"
              styleNavLink={styleNavLink}
              icon={faSuitcase}
              items={submenus[3]}
              toggleSidebar={toggleDependHideNav}
            />
            <SubMenu
              title="Modul Aset Tetap"
              styleNavLink={styleNavLink}
              icon={faBuilding}
              items={submenus[4]}
              toggleSidebar={toggleDependHideNav}
            />
            <SubMenu
              title="Laporan Keuangan"
              styleNavLink={styleNavLink}
              icon={faMoneyBill}
              items={submenus[1]}
              toggleSidebar={toggleDependHideNav}
            />
            <SubMenu
              title="Modul Admin"
              styleNavLink={styleNavLink}
              icon={faUserCog}
              items={submenus[0]}
              toggleSidebar={toggleDependHideNav}
            />
          </Nav>
        </div>
      </div>
    </>
  );
}

const submenus = [
  [
    {
      title: "Entitas",
      target: "entity",
    },
    {
      title: "User",
      target: "user",
    },
    {
      title: "Rule",
      target: "rule",
    },
  ],
  [
    {
      title: "Akun",
      target: "account",
    },
    {
      title: "Buku Besar",
      target: "ledger",
    },
    {
      title: "Laporan Laba Rugi",
      target: "income-statement",
    },
  ],
  [
    {
      title: "Setting Rekening Kas",
      target: "cash-account",
    },
    {
      title: "Buku Kas",
      target: "cash-book",
    },
  ],
  [
    {
      title: "Kategori Persediaan",
      target: "inventory-category",
    },
    {
      title: "Unit Pengukuran",
      target: "measurement",
    },
    {
      title: "Master Persediaan",
      target: "inventory",
    },
    {
      title: "Mutasi Persediaan",
      target: "inventory-mutation",
    },
    {
      title: "Saldo Persediaan",
      target: "inventory-balance",
    },
  ],
  [
    {
      title: "Master Aset Tetap",
      target: "fixed-asset",
    },
    {
      title: "Akuisisi Aset Tetap",
      target: "fixed-asset-acquisition",
    },
    {
      title: "Disposisi Aset Tetap",
      target: "fixed-asset-disposition",
    },
  ],
];

// export default SideBar;
