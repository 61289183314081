import React from "react";
import { Table } from "reactstrap";
export default function Footer() {
  return (
    <>
      <footer className="py-3 my-4">
        <Table borderless>
          <tbody>
            <tr>
              <td colSpan={3}>
                <hr />
              </td>
            </tr>
            <tr>
              {/* <td width="10%">
                <img src={CARD} className="rounded img-fluid" alt="Card"></img>
              </td> */}
              <td className="text-center text-muted b-3 mb-3">
                Centre for Accounting Research & Development
                <br />
                Fakultas Ekonomika dan Bisnis
                <br />
                Universitas Kristen Satya Wacana <br />
                &copy; 2022 Card
              </td>
              {/* <td width="10%">
                <img
                  src={UKSW}
                  alt="uksw"
                  className="rounded img-fluid"
                  width="55%"
                ></img>
              </td> */}
            </tr>
          </tbody>
        </Table>
      </footer>
    </>
  );
}
