import React, { useState } from "react";
import classNames from "classnames";
import { Collapse, NavItem, NavLink } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";

const SubMenu = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const toggle = () => setCollapsed(!collapsed);
  const closeSidebar = () => {
    // toggle();
    toggleSidebar();
  };
  const { icon, title, items, styleNavLink, toggleSidebar } = props;

  return (
    <div>
      <NavItem
        onClick={toggle}
        className={classNames({ "menu-open": !collapsed })}
      >
        <NavLink className="dropdown-toggle-x mb-2" style={styleNavLink}>
          <div>
            <div className="d-inline">
              <FontAwesomeIcon icon={icon} className="me-2  ms-3" />
              {title}
            </div>
            <div className="d-inline ms-auto float-end">
              {!collapsed ? (
                <FontAwesomeIcon icon={faCaretRight} className="ms-auto" />
              ) : (
                <FontAwesomeIcon icon={faCaretDown} className="ms-auto" />
              )}
            </div>
          </div>
        </NavLink>
      </NavItem>
      <Collapse
        isOpen={!collapsed}
        navbar
        className={classNames("items-menu", { "mb-1": !collapsed })}
      >
        {items.map((item, index) => (
          <NavItem key={index} className="ms-5">
            <NavLink
              tag={Link}
              to={item.target}
              style={{ color: "black" }}
              onClick={() => closeSidebar()}
            >
              {item.title}
            </NavLink>
          </NavItem>
        ))}
      </Collapse>
    </div>
  );
};

export default SubMenu;
