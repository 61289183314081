import {
  dispatchError,
  dispatchLoading,
  dispatchSuccess,
  getAuthorizationHeader,
} from "../utils";
import axios from "axios";

export const GET_MEASUREMENT_LIST = "GET_MEASUREMENT_LIST";
export const EDIT_MEASUREMENT = "EDIT_MEASUREMENT";
export const ADD_MEASUREMENT = "ADD_MEASUREMENT";
export const DELETE_MEASUREMENT = "DELETE_MEASUREMENT";

export const getMeasurementList = (idEntity) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_MEASUREMENT_LIST);
    axios({
      method: "GET",
      url: "measurement?id_entity=" + idEntity,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, GET_MEASUREMENT_LIST, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, GET_MEASUREMENT_LIST, error.response.data);
      });
  };
};

export const editMeasurement = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, EDIT_MEASUREMENT);
    axios({
      method: "PUT",
      url: "measurement",
      data,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, EDIT_MEASUREMENT, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, EDIT_MEASUREMENT, error.response.data);
      });
  };
};
export const deleteMeasurement = (id) => {
  return (dispatch) => {
    dispatchLoading(dispatch, DELETE_MEASUREMENT);
    axios({
      method: "DELETE",
      url: "measurement/" + id,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, DELETE_MEASUREMENT, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, DELETE_MEASUREMENT, error.response.data);
      });
  };
};
