import moment from "moment";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  Card,
  Col,
  Row,
  CardHeader,
  CardTitle,
  CardBody,
  Spinner,
  Table,
  Input,
  Button,
} from "reactstrap";
import swal from "sweetalert";
import { getEntityList } from "../../../actions/EntityAction";
import { getInventoryMutationTotalList } from "../../../actions/InventoryMutationAction";
import { numberWithCommas } from "../../../utils";

class InventoryBalance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idEntity: "",
      searchText: "Cari",
      endDate: "",
      categories: false,
      mutationGroupByCategory: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(getEntityList());
  }

  componentDidUpdate(prevProps) {
    const { getInventoryMutationAllResult, getInventoryMutationAllError } =
      this.props;

    if (
      getInventoryMutationAllResult &&
      prevProps.getInventoryMutationAllResult !== getInventoryMutationAllResult
    ) {
      this.setState({
        categories: getInventoryMutationAllResult.category_data,
      });
      this.setInventoryMutationBalance(
        getInventoryMutationAllResult.inventory_data,
        getInventoryMutationAllResult.category_data,
        getInventoryMutationAllResult.data
      );
    }

    if (
      getInventoryMutationAllError &&
      prevProps.getInventoryMutationAllError !== getInventoryMutationAllError
    ) {
      swal("Error", getInventoryMutationAllError.messages.error, "error");
    }
  }

  setInventoryMutationBalance = (inventoryData, categoryData, data) => {
    const mutationGroupByCategoryTemp = [];

    categoryData.forEach((category) => {
      let inventoriesGroupByCategory = inventoryData.filter(
        (inventory) => inventory.id_inventory_category === category.id
      );

      inventoriesGroupByCategory.forEach((inventory) => {
        let mutationGroupByInventory = data.filter(
          (detail) => detail.id_inventory === inventory.id
        );
        let grandPrice = 0;
        let grandAmount = 0;
        let grandTotal = 0;
        if (mutationGroupByInventory.length > 0) {
          mutationGroupByInventory.forEach((mutation) => {
            if (mutation.type === "out") {
              grandAmount = grandAmount - parseFloat(mutation.amount);
              grandTotal =
                grandTotal - parseFloat(mutation.amount) * grandPrice;
              mutation.price = grandPrice;
              mutation.total = grandPrice * parseFloat(mutation.amount);
            } else {
              grandAmount = grandAmount + parseFloat(mutation.amount);
              grandTotal =
                grandTotal +
                parseFloat(mutation.amount) * parseFloat(mutation.price);
              grandPrice = grandTotal / grandAmount;
            }

            mutation.grand_total = grandTotal;
            mutation.grand_price = grandPrice;
            mutation.grand_amount = grandAmount;
          });

          mutationGroupByCategoryTemp.push(
            mutationGroupByInventory[mutationGroupByInventory.length - 1]
          );
        }
      });
    });

    this.setState({
      mutationGroupByCategory: mutationGroupByCategoryTemp,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleSelectChange = (event) => {
    const value = event.target.value;
    this.setState({
      idEntity: value,
      searchText: "Cari",
    });
  };

  getData = () => {
    const { idEntity, endDate } = this.state;
    if (idEntity && endDate) {
      this.setState({
        mutationGroupByCategory: false,
        categories: false,
      });
      this.props.dispatch(getInventoryMutationTotalList(idEntity, endDate));
    } else
      swal(
        "Error",
        "Pilih entitas dan akhir data yang akan ditampilkan!",
        "error"
      );
  };

  render() {
    const {
      idEntity,
      searchText,
      endDate,
      categories,
      mutationGroupByCategory,
    } = this.state;
    const { getEntityLoading, getEntityResult } = this.props;
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Laporan Mutasi Persediaan</CardTitle>
              </CardHeader>
              <CardBody>
                <Table borderless>
                  <tbody>
                    {getEntityLoading ? (
                      <tr>
                        <td className="text-center">
                          <Spinner color="primary" />
                        </td>
                      </tr>
                    ) : (
                      <>
                        <tr>
                          <td width="20%">
                            <Input
                              type="select"
                              name="idEntity"
                              onChange={(event) =>
                                this.handleSelectChange(event)
                              }
                              value={idEntity}
                              options={getEntityResult.data}
                              style={{ display: "block", margin: "auto" }}
                            >
                              <option value="">--Pilih Entitas--</option>
                              {getEntityResult &&
                                getEntityResult.data.map((entity, index) => (
                                  <option key={index} value={entity.id}>
                                    {entity.entity_name}
                                  </option>
                                ))}
                            </Input>
                          </td>
                          <td width="20%">
                            <Input
                              type="date"
                              value={endDate}
                              name="endDate"
                              onChange={(event) => this.handleChange(event)}
                            />
                          </td>
                          <td>
                            <Button
                              color="success me-2"
                              disabled={
                                getEntityLoading || !idEntity || !endDate
                              }
                              onClick={this.getData}
                            >
                              {searchText}
                            </Button>
                          </td>
                        </tr>
                      </>
                    )}
                  </tbody>
                </Table>
                {categories && (
                  <Table responsive bordered>
                    <tbody>
                      <tr>
                        <td width="15%">Tanggal Laporan</td>
                        <td colSpan={4}>
                          {moment(endDate).format("DD MMMM YYYY")}
                        </td>
                      </tr>
                      <tr>
                        <th colSpan={2}></th>
                        <th className="text-center">Harga</th>
                        <th className="text-center">Jumlah</th>
                        <th className="text-center">Saldo</th>
                      </tr>
                      {categories.map((category, index2) => (
                        <Fragment key={index2}>
                          <tr>
                            <td colSpan={5}>{category.name}</td>
                          </tr>
                          {mutationGroupByCategory
                            .filter(
                              (mutation) =>
                                mutation.id_inventory_category === category.id
                            )
                            .map((mutation) => (
                              <tr key={mutation.id_inventory}>
                                <td>{mutation.code}</td>
                                <td>{mutation.name}</td>
                                <td>
                                  {numberWithCommas(
                                    Math.round(mutation.grand_price)
                                  )}
                                </td>
                                <td>
                                  {numberWithCommas(
                                    Math.round(mutation.grand_amount)
                                  )}
                                </td>
                                <td>
                                  {numberWithCommas(
                                    Math.round(mutation.grand_total)
                                  )}
                                </td>
                              </tr>
                            ))}
                          <tr>
                            <td colSpan={5}></td>
                          </tr>
                        </Fragment>
                      ))}
                    </tbody>
                  </Table>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  getEntityLoading: state.EntityReducer.getEntityLoading,
  getEntityResult: state.EntityReducer.getEntityResult,
  getEntityError: state.EntityReducer.getEntityError,

  getInventoryMutationAllLoading:
    state.InventoryMutationReducer.getInventoryMutationAllLoading,
  getInventoryMutationAllResult:
    state.InventoryMutationReducer.getInventoryMutationAllResult,
  getInventoryMutationAllError:
    state.InventoryMutationReducer.getInventoryMutationAllError,
});

export default connect(mapStateToProps, null)(InventoryBalance);
