import {
  dispatchError,
  dispatchLoading,
  dispatchSuccess,
  getAuthorizationHeader,
} from "../utils";
import axios from "axios";

export const GET_FIXED_ASSET_CATEGORY_LIST = "GET_FIXED_ASSET_CATEGORY_LIST";

export const getFixedAssetCategoryList = () => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_FIXED_ASSET_CATEGORY_LIST);
    axios({
      method: "GET",
      url: "fixed-asset-category",
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, GET_FIXED_ASSET_CATEGORY_LIST, response.data);
      })
      .catch((error) => {
        dispatchError(
          dispatch,
          GET_FIXED_ASSET_CATEGORY_LIST,
          error.response.data
        );
      });
  };
};
