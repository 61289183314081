import {
  dispatchError,
  dispatchLoading,
  dispatchSuccess,
  getAuthorizationHeader,
} from "../utils";
import axios from "axios";

export const GET_LEDGER_LIST = "GET_LEDGER_LIST";

export const getLedgerList = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_LEDGER_LIST);
    axios({
      method: "GET",
      url:
        "ledger?id_account=" +
        data.idAccount +
        "&start_date=" +
        data.startDateShow +
        "&end_date=" +
        data.endDateShow,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, GET_LEDGER_LIST, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, GET_LEDGER_LIST, error.response.data);
      });
  };
};
