import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import swal from "sweetalert";
import { getEntityList } from "../../../actions/EntityAction";
import {
  addDispositionFixedAsset,
  getDispositionFixedAssetList,
  getFixedAssetList,
} from "../../../actions/FixedAssetAction";
import { AddDispositionModal } from "../../../components/private/fixedAsset/AddDispositionModal";
import SpinnerTable from "../../../components/spinner/SpinnerTable";
import {
  PaginationComponent,
  SearchComponent,
} from "../../../components/Table";
import EmptyData from "../../../components/Table/EmptyData";
import { numberWithCommas } from "../../../utils";

class FixedAssetDispositionList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idEntity: "",
      fixedAssets: false,
      searchText: "Cari",
      searchedText: "",
      keyword: "",
      showAddAqcuisitionModal: false,
      selectedFixedAsset: {
        id: 0,
        dispositionDate: "",
      },

      totalData: 0,
      currentPage: 0,
      pageSize: 10,
      pagesCount: 0,
    };
  }

  componentDidMount() {
    this.props.dispatch(getEntityList());
  }

  componentDidUpdate(prevProps) {
    const {
      getFixedAssetResult,
      getFixedAssetError,
      getDispositionFixedAssetError,
      addDispositionFixedAssetResult,
      addDispositionFixedAssetError,
    } = this.props;

    if (
      getFixedAssetResult &&
      prevProps.getFixedAssetResult !== getFixedAssetResult
    ) {
      this.setState({
        fixedAssets: getFixedAssetResult.data,
        selectedFixedAsset: false,
        showAddAqcuisitionModal: false,
        showAddDepreciationModal: false,
        totalData: getFixedAssetResult.data.length,
        pagesCount: Math.ceil(
          getFixedAssetResult.data.length / this.state.pageSize
        ),
      });
    }

    if (
      addDispositionFixedAssetResult &&
      prevProps.addDispositionFixedAssetResult !==
        addDispositionFixedAssetResult
    ) {
      swal("Sukses", addDispositionFixedAssetResult.messages, "success");
      this.getData();
    }

    if (
      getFixedAssetError &&
      prevProps.getFixedAssetError !== getFixedAssetError
    ) {
      swal("Error", getFixedAssetError.messages.error, "error");
    }

    if (
      getDispositionFixedAssetError &&
      prevProps.getDispositionFixedAssetError !== getDispositionFixedAssetError
    ) {
      swal("Error", getDispositionFixedAssetError.messages.error, "error");
    }

    if (
      addDispositionFixedAssetError &&
      prevProps.addDispositionFixedAssetError !== addDispositionFixedAssetError
    ) {
      swal("Error", addDispositionFixedAssetError.messages.error, "error");
    }
  }

  handleSelectChange = (event) => {
    const value = event.target.value;
    this.setState({
      [event.target.name]: value,
      searchText: "Cari",
      fixedAssets: false,
    });
  };

  getData = () => {
    const { idEntity } = this.state;
    if (idEntity) {
      this.props.dispatch(getFixedAssetList(idEntity, "true", "true"));
      this.props.dispatch(getDispositionFixedAssetList(idEntity));
    }
  };

  handleChangeSelectedFixedAsset = (event) => {
    const { selectedFixedAsset } = this.state;
    const { getDispositionFixedAssetResult } = this.props;

    if (event.target.name === "id") {
      const selectedAsset = getDispositionFixedAssetResult.data.filter(
        (asset) => asset.id === event.target.value
      );

      this.setState({
        selectedFixedAsset: {
          ...selectedFixedAsset,
          id: selectedAsset.length === 0 ? "" : selectedAsset[0].id,
          name: selectedAsset.length === 0 ? "" : selectedAsset[0].name,
          acquisition_value:
            selectedAsset.length === 0
              ? ""
              : selectedAsset[0].acquisition_value,
          economicAge:
            selectedAsset.length === 0 ? "" : selectedAsset[0].economic_age,
          depreciation_month_total_temp:
            selectedAsset.length === 0
              ? ""
              : selectedAsset[0].depreciation_month_total_temp,
          depreciation_accumulation_temp:
            selectedAsset.length === 0
              ? ""
              : selectedAsset[0].depreciation_accumulation_temp,
          depreciation_loss_temp:
            selectedAsset.length === 0
              ? ""
              : selectedAsset[0].depreciation_loss_temp,
        },
      });
    } else {
      this.setState({
        selectedFixedAsset: {
          ...selectedFixedAsset,
          [event.target.name]: event.target.value,
        },
      });
    }
  };

  handleSubmit = () => {
    const { selectedFixedAsset } = this.state;
    if (
      selectedFixedAsset.id &&
      selectedFixedAsset.dispositionDate &&
      parseInt(selectedFixedAsset.id) > 0
    ) {
      this.props.dispatch(addDispositionFixedAsset(selectedFixedAsset));
    } else swal("Error", "Isi data disposisi dengan lengkap", "error");
  };

  render() {
    const {
      idEntity,
      searchText,
      fixedAssets,
      keyword,
      searchedText,
      currentPage,
      pageSize,
      pagesCount,
      totalData,
    } = this.state;
    const { getEntityLoading, getEntityResult, getFixedAssetLoading } =
      this.props;
    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Disposisi Aset Tetap</CardTitle>
              </CardHeader>
              <CardBody>
                <Table borderless>
                  <tbody>
                    {getEntityLoading ? (
                      <tr>
                        <td className="text-center">
                          <Spinner color="primary" />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td width="20%">
                          <Input
                            type="select"
                            name="idEntity"
                            onChange={(event) => this.handleSelectChange(event)}
                            value={idEntity}
                            options={getEntityResult.data}
                            style={{ display: "block", margin: "auto" }}
                          >
                            <option value="">--Pilih Entitas--</option>
                            {getEntityResult &&
                              getEntityResult.data.map((entity, index) => (
                                <option key={index} value={entity.id}>
                                  {entity.entity_name}
                                </option>
                              ))}
                          </Input>
                        </td>
                        <td>
                          <Button
                            color="success me-2"
                            disabled={getEntityLoading}
                            onClick={this.getData}
                          >
                            {searchText}
                          </Button>
                        </td>
                        <td className="float-end">
                          <Button
                            color="primary me-2"
                            disabled={!fixedAssets}
                            onClick={() =>
                              this.setState({ showAddAqcuisitionModal: true })
                            }
                          >
                            Tambah
                          </Button>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td></td>
                      <td></td>
                      <td className="float-end">
                        <br />
                        <SearchComponent
                          keyword={keyword}
                          searchedText={searchedText}
                          keywordHandleSubmit={this.filterHandleSubmit}
                          handleChange={this.handleChangeText}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th width="5%">No</th>
                      <th width="25%">Data Aset</th>
                      <th width="25%">Akuisisi</th>
                      <th width="30%">Disposisi</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fixedAssets && fixedAssets.length > 0 ? (
                      fixedAssets
                        .filter((asset) =>
                          asset.code
                            .toLowerCase()
                            .includes(searchedText.toLowerCase()) ||
                          asset.name
                            .toLowerCase()
                            .includes(searchedText.toLowerCase())
                            ? true
                            : false
                        )
                        .slice(
                          currentPage * pageSize,
                          (currentPage + 1) * pageSize
                        )
                        .map((asset, index) => (
                          <tr key={index}>
                            <td>{currentPage * pageSize + index + 1}</td>
                            <td>
                              <Table borderless>
                                <tbody>
                                  <tr>
                                    <td width="30%">Nomor</td>
                                    <td width="1%">:</td>
                                    <td>{asset.code}</td>
                                  </tr>
                                  <tr>
                                    <td>Nama</td>
                                    <td>:</td>
                                    <td>{asset.name}</td>
                                  </tr>
                                  <tr>
                                    <td>Umur Ekonomi</td>
                                    <td>:</td>
                                    <td>{asset.economic_age + " Bulan"} </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </td>
                            <td>
                              <Table borderless>
                                <tbody>
                                  <tr>
                                    <td width="15%">Tanggal</td>
                                    <td width="1%">:</td>
                                    <td>
                                      {moment(asset.acquisition_date).format(
                                        "DD MMMM YYYY"
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Nilai</td>
                                    <td>:</td>
                                    <td>
                                      Rp.
                                      {" " +
                                        numberWithCommas(
                                          asset.acquisition_value
                                        )}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </td>
                            <td>
                              <Table borderless>
                                <tbody>
                                  <tr>
                                    <td width="40%">Tanggal</td>
                                    <td width="1%">:</td>
                                    <td>
                                      {moment(asset.disposition_date).format(
                                        "DD MMMM YYYY"
                                      )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Akumulasi Depresiasi</td>
                                    <td>:</td>
                                    <td>
                                      Rp.
                                      {" " +
                                        numberWithCommas(
                                          asset.depreciation_accumulation
                                        )}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Rugi Disposisi</td>
                                    <td>:</td>
                                    <td>
                                      Rp.
                                      {" " +
                                        parseInt(asset.depreciation_loss) >=
                                      0
                                        ? numberWithCommas(
                                            asset.depreciation_loss
                                          )
                                        : "-"}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </td>
                          </tr>
                        ))
                    ) : getFixedAssetLoading ? (
                      <SpinnerTable colSpan={7} />
                    ) : (
                      <EmptyData colSpan={7} />
                    )}
                  </tbody>
                </Table>
                {!searchedText && (
                  <>
                    <br />
                    <PaginationComponent
                      currentPage={currentPage}
                      pagesCount={pagesCount}
                      totalData={totalData}
                      pageSize={pageSize}
                      handleClick={this.handleClick}
                    />
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
        <AddDispositionModal
          modalToggle={this.state.showAddAqcuisitionModal}
          handleClose={() => this.setState({ showAddAqcuisitionModal: false })}
          handleChange={this.handleChangeSelectedFixedAsset}
          selectedFixedAsset={this.state.selectedFixedAsset}
          handleSubmit={this.handleSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  getEntityLoading: state.EntityReducer.getEntityLoading,
  getEntityResult: state.EntityReducer.getEntityResult,
  getEntityError: state.EntityReducer.getEntityError,

  getFixedAssetLoading: state.FixedAssetReducer.getFixedAssetLoading,
  getFixedAssetResult: state.FixedAssetReducer.getFixedAssetResult,
  getFixedAssetError: state.FixedAssetReducer.getFixedAssetError,

  getDispositionFixedAssetLoading:
    state.FixedAssetReducer.getDispositionFixedAssetLoading,
  getDispositionFixedAssetResult:
    state.FixedAssetReducer.getDispositionFixedAssetResult,
  getDispositionFixedAssetError:
    state.FixedAssetReducer.getDispositionFixedAssetError,

  addDispositionFixedAssetLoading:
    state.FixedAssetReducer.addDispositionFixedAssetLoading,
  addDispositionFixedAssetResult:
    state.FixedAssetReducer.addDispositionFixedAssetResult,
  addDispositionFixedAssetError:
    state.FixedAssetReducer.addDispositionFixedAssetError,
});

export default connect(mapStateToProps, null)(FixedAssetDispositionList);
