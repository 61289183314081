import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import swal from "sweetalert";
import { getEntityList } from "../../../actions/EntityAction";
import {
  deleteInventoryCategory,
  editInventoryCategory,
  getInventoryCategoryList,
} from "../../../actions/InventoryCategoryAction";
import SpinnerTable from "../../../components/spinner/SpinnerTable";
import EmptyData from "../../../components/Table/EmptyData";

class InventoryCategoryList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idEntity: "",
      categories: false,
      isEditing: false,
      searchText: "Cari",
    };
  }

  componentDidMount() {
    this.props.dispatch(getEntityList());
  }

  componentDidUpdate(prevProps) {
    const {
      getInventoryCategoryResult,
      getInventoryCategoryError,
      editInventoryCategoryResult,
      editInventoryCategoryError,
      deleteInventoryCategoryResult,
      deleteInventoryCategoryError,
    } = this.props;
    if (
      getInventoryCategoryResult &&
      prevProps.getInventoryCategoryResult !== getInventoryCategoryResult
    ) {
      this.setState({
        categories: getInventoryCategoryResult.data,
        isEditing: false,
      });
    }

    if (
      editInventoryCategoryResult &&
      prevProps.editInventoryCategoryResult !== editInventoryCategoryResult
    ) {
      this.setState({
        isEditing: false,
      });
      this.props.dispatch(getInventoryCategoryList(this.state.idEntity));
      swal("Sukses", editInventoryCategoryResult.messages, "success");
    }

    if (
      deleteInventoryCategoryResult &&
      prevProps.deleteInventoryCategoryResult !== deleteInventoryCategoryResult
    ) {
      this.setState({
        isEditing: false,
      });
      this.getData();
      swal("Sukses", deleteInventoryCategoryResult.messages, "success");
    }

    if (
      getInventoryCategoryError &&
      prevProps.getInventoryCategoryError !== getInventoryCategoryError
    ) {
      swal("Error", getInventoryCategoryError.messages.error, "error");
    }

    if (
      editInventoryCategoryError &&
      prevProps.editInventoryCategoryError !== editInventoryCategoryError
    ) {
      swal("Error", editInventoryCategoryError.messages.error, "error");
    }

    if (
      deleteInventoryCategoryError &&
      prevProps.deleteInventoryCategoryError !== deleteInventoryCategoryError
    ) {
      swal("Error", deleteInventoryCategoryError.messages.error, "error");
    }
  }

  getData = () => {
    const { idEntity } = this.state;
    if (idEntity) {
      this.props.dispatch(getInventoryCategoryList(idEntity));
    }
  };

  handleSelectChange = (event) => {
    const value = event.target.value;
    this.setState({
      [event.target.name]: value,
      searchText: "Cari",
      categories: false,
    });
  };

  addData = () => {
    const { categories, idEntity } = this.state;
    const template = {
      id: "",
      id_entity: idEntity,
      code: "",
      name: "",
    };

    const categoriesTemp = [...categories];
    categoriesTemp.push(template);

    this.setState({
      categories: categoriesTemp.sort((a, b) => a.code.localeCompare(b.code)),
    });
  };

  saveChanging = () => {
    const { categories, isEditing } = this.state;

    if (isEditing) this.props.dispatch(editInventoryCategory(categories));
    else swal("Error", "Tidak ada data yang harus disimpan!!", "error");
  };

  handleChange = (event, index) => {
    const { categories } = this.state;
    const updatedCategories = [...categories];
    updatedCategories[index][event.target.name] = event.target.value;

    this.setState({
      categories: updatedCategories,
      isEditing: true,
    });
  };

  deleteData = (id, index) => {
    const { isEditing, categories } = this.state;

    if (id) {
      if (isEditing)
        swal(
          "Error",
          "Data sedang diubah, simpan data terlebih dahulu!",
          "error"
        );
      else {
        this.props.dispatch(deleteInventoryCategory(id));
      }
    } else {
      const deletedData = [...categories];
      deletedData.splice(index, 1);
      this.setState({ categories: deletedData });
    }
  };

  render() {
    const { idEntity, searchText, categories } = this.state;
    const {
      getEntityLoading,
      getEntityResult,
      getInventoryCategoryLoading,
      deleteInventoryCategoryLoading,
      editInventoryCategoryLoading,
    } = this.props;

    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Master Kategori Persediaan</CardTitle>
              </CardHeader>
              <CardBody>
                <Table borderless>
                  <tbody>
                    {getEntityLoading ? (
                      <tr>
                        <td className="text-center">
                          <Spinner color="primary" />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td width="20%">
                          <Input
                            type="select"
                            name="idEntity"
                            onChange={(event) => this.handleSelectChange(event)}
                            value={idEntity}
                            options={getEntityResult.data}
                            style={{ display: "block", margin: "auto" }}
                          >
                            <option value="">--Pilih Entitas--</option>
                            {getEntityResult &&
                              getEntityResult.data.map((entity, index) => (
                                <option key={index} value={entity.id}>
                                  {entity.entity_name}
                                </option>
                              ))}
                          </Input>
                        </td>
                        <td>
                          <Button
                            color="success me-2"
                            disabled={getEntityLoading}
                            onClick={this.getData}
                          >
                            {searchText}
                          </Button>
                        </td>
                        <td className="float-end">
                          <Button
                            color="primary me-2"
                            disabled={!categories}
                            onClick={() => this.addData()}
                          >
                            Tambah
                          </Button>
                          <Button
                            color="warning me-2"
                            disabled={!categories}
                            onClick={this.saveChanging}
                          >
                            Simpan
                          </Button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th width="5%">No</th>
                      <th width="30%">Kode</th>
                      <th>Kategory</th>
                      <th width="5%" className="text-center">
                        *
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!deleteInventoryCategoryLoading &&
                    !editInventoryCategoryLoading &&
                    categories &&
                    categories.length > 0 ? (
                      categories.map((category, index) => (
                        <tr key={index}>
                          <td className="align-middle">{index + 1}</td>
                          <td>
                            <Input
                              type="text"
                              value={category.code}
                              name="code"
                              autoComplete="off"
                              onChange={(event) =>
                                this.handleChange(event, index)
                              }
                            />
                          </td>
                          <td>
                            <Input
                              type="text"
                              value={category.name}
                              name="name"
                              autoComplete="off"
                              onChange={(event) =>
                                this.handleChange(event, index)
                              }
                            />
                          </td>
                          <td className="text-center">
                            <Button
                              color="danger"
                              onClick={() =>
                                this.deleteData(category.id, index)
                              }
                            >
                              X
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : getInventoryCategoryLoading ||
                      editInventoryCategoryLoading ||
                      deleteInventoryCategoryLoading ? (
                      <SpinnerTable colSpan={4} />
                    ) : (
                      <EmptyData colSpan={4} />
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  getEntityLoading: state.EntityReducer.getEntityLoading,
  getEntityResult: state.EntityReducer.getEntityResult,
  getEntityError: state.EntityReducer.getEntityError,

  getInventoryCategoryLoading:
    state.InventoryCategoryReducer.getInventoryCategoryLoading,
  getInventoryCategoryResult:
    state.InventoryCategoryReducer.getInventoryCategoryResult,
  getInventoryCategoryError:
    state.InventoryCategoryReducer.getInventoryCategoryError,

  editInventoryCategoryLoading:
    state.InventoryCategoryReducer.editInventoryCategoryLoading,
  editInventoryCategoryResult:
    state.InventoryCategoryReducer.editInventoryCategoryResult,
  editInventoryCategoryError:
    state.InventoryCategoryReducer.editInventoryCategoryError,

  deleteInventoryCategoryLoading:
    state.InventoryCategoryReducer.deleteInventoryCategoryLoading,
  deleteInventoryCategoryResult:
    state.InventoryCategoryReducer.deleteInventoryCategoryResult,
  deleteInventoryCategoryError:
    state.InventoryCategoryReducer.deleteInventoryCategoryError,
});

export default connect(mapStateToProps, null)(InventoryCategoryList);
