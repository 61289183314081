import {
  dispatchError,
  dispatchLoading,
  dispatchSuccess,
  getAuthorizationHeader,
} from "../utils";
import axios from "axios";

export const GET_FIXED_ASSET_LIST = "GET_FIXED_ASSET_LIST";
export const GET_ACQUISITION_FIXED_ASSET_LIST =
  "GET_ACQUISITION_FIXED_ASSET_LIST";
export const GET_DISPOSITION_FIXED_ASSET_LIST =
  "GET_DISPOSITION_FIXED_ASSET_LIST";
export const ADD_ACQUISITION_FIXED_ASSET = "ADD_ACQUISITION_FIXED_ASSET";
export const ADD_DISPOSITION_FIXED_ASSET = "ADD_DISPOSITION_FIXED_ASSET";
export const EDIT_FIXED_ASSET = "EDIT_FIXED_ASSET";
export const DELETE_FIXED_ASSET = "DELETE_FIXED_ASSET";

export const getFixedAssetList = (
  idEntity,
  acquisition = "",
  disposition = ""
) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_FIXED_ASSET_LIST);
    axios({
      method: "GET",
      url:
        "fixed-asset?id_entity=" +
        idEntity +
        "&acquisition=" +
        acquisition +
        "&disposition=" +
        disposition,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, GET_FIXED_ASSET_LIST, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, GET_FIXED_ASSET_LIST, error.response.data);
      });
  };
};

export const getAcquisitionFixedAssetList = (idEntity) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_ACQUISITION_FIXED_ASSET_LIST);
    axios({
      method: "GET",
      url: "fixed-asset?id_entity=" + idEntity + "&acquisition=false",
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(
          dispatch,
          GET_ACQUISITION_FIXED_ASSET_LIST,
          response.data
        );
      })
      .catch((error) => {
        dispatchError(
          dispatch,
          GET_ACQUISITION_FIXED_ASSET_LIST,
          error.response.data
        );
      });
  };
};

export const getDispositionFixedAssetList = (idEntity) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_DISPOSITION_FIXED_ASSET_LIST);
    axios({
      method: "GET",
      url:
        "fixed-asset?id_entity=" +
        idEntity +
        "&acquisition=true&disposition=false&depreciation=true",
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(
          dispatch,
          GET_DISPOSITION_FIXED_ASSET_LIST,
          response.data
        );
      })
      .catch((error) => {
        dispatchError(
          dispatch,
          GET_DISPOSITION_FIXED_ASSET_LIST,
          error.response.data
        );
      });
  };
};

export const addAcquisitionFixedAsset = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, ADD_ACQUISITION_FIXED_ASSET);
    axios({
      method: "POST",
      url: "fixed-asset-acquisition",
      data,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, ADD_ACQUISITION_FIXED_ASSET, response.data);
      })
      .catch((error) => {
        dispatchError(
          dispatch,
          ADD_ACQUISITION_FIXED_ASSET,
          error.response.data
        );
      });
  };
};

export const addDispositionFixedAsset = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, ADD_DISPOSITION_FIXED_ASSET);
    axios({
      method: "POST",
      url: "fixed-asset-disposition",
      data,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, ADD_DISPOSITION_FIXED_ASSET, response.data);
      })
      .catch((error) => {
        dispatchError(
          dispatch,
          ADD_DISPOSITION_FIXED_ASSET,
          error.response.data
        );
      });
  };
};

export const addDepreciationFixedAsset = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, ADD_ACQUISITION_FIXED_ASSET);
    axios({
      method: "POST",
      url: "fixed-asset-depreciation",
      data,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, ADD_ACQUISITION_FIXED_ASSET, response.data);
      })
      .catch((error) => {
        dispatchError(
          dispatch,
          ADD_ACQUISITION_FIXED_ASSET,
          error.response.data
        );
      });
  };
};

export const editFixedAsset = (data) => {
  console.log(data);
  return (dispatch) => {
    dispatchLoading(dispatch, EDIT_FIXED_ASSET);
    axios({
      method: "PUT",
      url: "fixed-asset",
      data,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, EDIT_FIXED_ASSET, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, EDIT_FIXED_ASSET, error.response.data);
      });
  };
};
export const deleteFixedAsset = (id) => {
  return (dispatch) => {
    dispatchLoading(dispatch, DELETE_FIXED_ASSET);
    axios({
      method: "DELETE",
      url: "fixed-asset/" + id,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, DELETE_FIXED_ASSET, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, DELETE_FIXED_ASSET, error.response.data);
      });
  };
};
