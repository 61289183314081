import React, { Component } from "react";
import Carousel from "react-bootstrap/Carousel";
import UKSW from "../../images/uksw.png";
import CARD from "../../images/card.jpg";
import AKUNTANSI from "../../images/akuntansi.jpeg";
import FEB from "../../images/feb.png";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  // componentDidMount() {
  //   window.addEventListener("resize", this.resize.bind(this));
  //   this.resize();
  // }

  // resize() {
  //   let currentHideNav = window.innerWidth <= 800;
  //   if (currentHideNav !== this.state.hideNav) {
  //     this.setState({ hideNav: currentHideNav });
  //   }
  // }

  // componentWillUnmount() {
  //   window.removeEventListener("resize", this.resize.bind(this));
  // }

  render() {
    return (
      <div className="content">
        {this.props.hideNav ? (
          <div className="mt-5">
            <img src={CARD} className="rounded img-fluid" alt="Card"></img>
          </div>
        ) : (
          <Carousel variant="dark" touch>
            <Carousel.Item>
              <img
                className="d-block w-10"
                src={CARD}
                alt="First slide"
                width="25%"
              />
              <Carousel.Caption>
                <h2>SIAB_CARD</h2>
                <h3>Sistem Informasi Akuntansi BUMDes oleh CARD.</h3>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-10"
                src={UKSW}
                alt="First slide"
                width="13%"
              />
              <Carousel.Caption>
                <h2>UKSW</h2>
                <h3>Universitas Kristen Satya Wacana</h3>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-10"
                src={FEB}
                alt="First slide"
                width="15%"
              />
              <Carousel.Caption>
                <h2>FEB UKSW</h2>
                <h3>Fakultas Ekonomika dan Bisnis</h3>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-10"
                src={AKUNTANSI}
                alt="First slide"
                width="15%"
              />
              <Carousel.Caption>
                <h2>Departemen Akuntansi</h2>
                <h3>FEB UKSW</h3>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
        )}
      </div>
    );
  }
}
