import {
  dispatchError,
  dispatchLoading,
  dispatchSuccess,
  getAuthorizationHeader,
} from "../utils";
import axios from "axios";

export const GET_INVENTORY_MUTATION_LIST = "GET_INVENTORY_MUTATION_LIST";
export const GET_INVENTORY_MUTATION_ALL_LIST =
  "GET_INVENTORY_MUTATION_ALL_LIST";
export const ADD_INVENTORY_MUTATION = "ADD_INVENTORY_MUTATION";

export const getInventoryMutationList = (
  idEntity,
  idInventory,
  startDate,
  endDate
) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_INVENTORY_MUTATION_LIST);
    axios({
      method: "GET",
      url:
        "inventory-mutation?start_date=" +
        startDate +
        "&end_date=" +
        endDate +
        "&id_entity=" +
        idEntity +
        "&id_inventory=" +
        idInventory,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, GET_INVENTORY_MUTATION_LIST, response.data);
      })
      .catch((error) => {
        dispatchError(
          dispatch,
          GET_INVENTORY_MUTATION_LIST,
          error.response.data
        );
      });
  };
};

export const getInventoryMutationAllList = (idEntity, idInventory) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_INVENTORY_MUTATION_ALL_LIST);
    axios({
      method: "GET",
      url:
        "inventory-mutation?id_entity=" +
        idEntity +
        "&id_inventory=" +
        idInventory,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(
          dispatch,
          GET_INVENTORY_MUTATION_ALL_LIST,
          response.data
        );
      })
      .catch((error) => {
        dispatchError(
          dispatch,
          GET_INVENTORY_MUTATION_ALL_LIST,
          error.response.data
        );
      });
  };
};

export const getInventoryMutationTotalList = (idEntity, endDate) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_INVENTORY_MUTATION_ALL_LIST);
    axios({
      method: "GET",
      url: "inventory-report?id_entity=" + idEntity + "&end_date=" + endDate,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(
          dispatch,
          GET_INVENTORY_MUTATION_ALL_LIST,
          response.data
        );
      })
      .catch((error) => {
        dispatchError(
          dispatch,
          GET_INVENTORY_MUTATION_ALL_LIST,
          error.response.data
        );
      });
  };
};

export const addInventoryMutation = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, ADD_INVENTORY_MUTATION);
    axios({
      method: "POST",
      url: "inventory-mutation",
      data,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, ADD_INVENTORY_MUTATION, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, ADD_INVENTORY_MUTATION, error.response.data);
      });
  };
};
