import React from "react";

import { Button, Input, Table } from "reactstrap";
import Modal from "react-bootstrap/Modal";
import { MONTH_LIST, getYearList } from "../../../utils";
import SpinnerTable from "../../spinner/SpinnerTable";
import { useSelector } from "react-redux";

export const AddDepreciationModal = ({
  modalToggle,
  handleClose,
  handleSubmit,
  handleChange,
  depreciationMonth,
  depreciationYear,
}) => {
  const { addAcquisitionFixedAssetLoading } = useSelector(
    (state) => state.FixedAssetReducer
  );

  return (
    <Modal show={modalToggle} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Depresiasi Aset Tetap</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table responsive>
          <tbody>
            {addAcquisitionFixedAssetLoading ? (
              <SpinnerTable colSpan={3} />
            ) : (
              <>
                <tr>
                  <th width="25%">Field</th>
                  <th colSpan={2} className="text-center">
                    Mandatory
                  </th>
                </tr>
                <tr>
                  <td className="align-middle">Bulan-Tahun</td>
                  <td>
                    <Input
                      type="select"
                      name="depreciationMonth"
                      onChange={(event) => handleChange(event)}
                      value={depreciationMonth}
                      //   options={getEntityResult.data}
                      style={{ display: "block", margin: "auto" }}
                    >
                      {MONTH_LIST &&
                        MONTH_LIST.map((month, index) => (
                          <option key={index} value={month.id}>
                            {month.name}
                          </option>
                        ))}
                    </Input>
                  </td>
                  <td>
                    <Input
                      type="select"
                      name="depreciationYear"
                      onChange={(event) => handleChange(event)}
                      value={depreciationYear}
                      //   options={getEntityResult.data}
                      style={{ display: "block", margin: "auto" }}
                    >
                      {getYearList().map((year, index) => (
                        <option key={index} value={year}>
                          {year}
                        </option>
                      ))}
                    </Input>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}></td>
                  <td className="text-end">
                    <Button color="primary" onClick={handleSubmit}>
                      Simpan
                    </Button>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};
