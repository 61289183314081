import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Input,
  Button,
  Table,
} from "reactstrap";
import swal from "sweetalert";
import {
  getCashInAccountList,
  getCashOutAccountList,
} from "../../../actions/AccountAction";
import { getCashAccountList } from "../../../actions/CashAccountAction";
import { getEntityList } from "../../../actions/EntityAction";
import {
  AddTransaction,
  getTransactionList,
} from "../../../actions/TransactionAction";
import AccountJournalModal from "../../../components/private/journal/AccountJournalModal";
import TransactionInModal from "../../../components/private/transaction/TransactionInModal";
import TransactionOutModal from "../../../components/private/transaction/TransactionOutModal";
import SpinnerRow from "../../../components/spinner/SpinnerRow";
import SpinnerTable from "../../../components/spinner/SpinnerTable";
import EmptyData from "../../../components/Table/EmptyData";
import { numberWithCommas } from "../../../utils";

class CashBook extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idEntity: "",
      entityName: "",
      cashAccounts: false,
      selectedCashAccount: false,
      detailCashAccounts: false,
      showTransactionOut: false,
      showTransactionIn: false,
      showJournal: false,
      transactionData: {
        number: "0000",
        id_cash_account: "",
        date: "",
        normal_balance: "D",
      },
      details: [],

      isEditing: false,
      searchText: "Cari",
      startDate: "", //moment().format("YYYY-MM-DD"),
      endDate: "", //moment().format("YYYY-MM-DD"),
      transactionList: false,
      selectedTransaction: false,
    };
  }

  componentDidMount() {
    this.getDefaultData();
  }

  componentDidUpdate(prevProps) {
    const {
      getCashAccountResult,
      addTransactionResult,
      addTransactionError,
      getTransactionResult,
    } = this.props;

    if (
      getCashAccountResult &&
      prevProps.getCashAccountResult !== getCashAccountResult
    ) {
      this.setData(getCashAccountResult.data);
    }

    if (
      addTransactionResult &&
      prevProps.addTransactionResult !== addTransactionResult
    ) {
      swal("Sukses", addTransactionResult.messages, "success");
      this.setState({
        showTransactionOut: false,
        showTransactionIn: false,
      });
      this.getTransactionData();
    }

    if (
      addTransactionError &&
      prevProps.addTransactionError !== addTransactionError
    ) {
      swal("Error", addTransactionError.messages.error, "error");
    }

    if (
      getTransactionResult &&
      prevProps.getTransactionResult !== getTransactionResult
    ) {
      this.calculateTransactionList(getTransactionResult.data);
    }
  }

  calculateTransactionList = (data) => {
    const { selectedCashAccount } = this.state;
    let totalBalance = parseFloat(selectedCashAccount.start_balance);
    data.forEach((detail, index) => {
      if (detail.normal_balance === "D") {
        totalBalance = totalBalance + parseFloat(detail.value);
        detail.balance = totalBalance;
      } else {
        totalBalance = totalBalance - parseFloat(detail.value);
        detail.balance = totalBalance;
      }
    });

    this.setState({ transactionList: data });
  };

  setData = (data) => {
    this.setState({
      cashAccounts: data,
      searchText: "Refresh",
      isEditing: false,
    });
  };

  getDefaultData = () => {
    const { idEntity } = this.state;
    if (idEntity) {
      this.setState({ cashAccounts: false, selectedCashAccount: false });
      this.props.dispatch(getCashAccountList(idEntity));
      this.props.dispatch(getCashInAccountList(idEntity));
      this.props.dispatch(getCashOutAccountList(idEntity));
    } else if (!idEntity) {
      this.props.dispatch(getEntityList());
    }
  };

  getTransactionData = () => {
    const { idEntity, startDate, endDate, selectedCashAccount } = this.state;

    if (startDate && endDate) {
      this.props.dispatch(
        getTransactionList(idEntity, startDate, endDate, selectedCashAccount.id)
      );
    } else {
      swal(
        "Error",
        "Isi tanggal awal dan akhir transaksi yang akan ditampilkan!",
        "error"
      );
    }
  };

  handleSelectChange = (event) => {
    const value = event.target.value;
    this.setState({
      [event.target.name]: value,
      searchText: "Cari",
      entityName:
        event.nativeEvent.target[event.nativeEvent.target.selectedIndex].text,
      cashAccounts: false,
      selectedCashAccount: false,
      detailCashAccounts: false,
      transactionList: false,
    });
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  handleChangeTransaction = (event) => {
    const { transactionData } = this.state;
    this.setState({
      transactionData: {
        ...transactionData,
        [event.target.name]: event.target.value,
      },
    });
  };

  selectCashAccount = (index) => {
    const { cashAccounts, transactionData } = this.state;

    this.setState({
      selectedCashAccount: cashAccounts[index],
      transactionList: false,
      transactionData: {
        ...transactionData,
        id_cash_account: cashAccounts[index].id,
      },
    });
  };

  transactionHandleSubmit = (event) => {
    const { transactionData, details } = this.state;
    event.preventDefault();
    if (transactionData.date === "") {
      swal("Error", "Isi tanggal transaksi", "error");
    } else if (!details) {
      swal("Error", "Isi detail transaksi", "error");
    } else {
      let status = 1;
      details.forEach((detail) => {
        if (detail.id_chart_of_account === "") {
          swal("Error", "Isi akun detail transaksi", "error");
          status = 0;
          return;
        }
        if (detail.note === "") {
          swal("Error", "Isi keterangan detail transaksi", "error");
          status = 0;
          return;
        }
        if (detail.value === "" || detail.value === "0") {
          swal("Error", "Isi nilai detail transaksi", "error");
          status = 0;
          return;
        }
      });

      if (status === 1) {
        this.props.dispatch(AddTransaction(this.state));
      }
    }
  };

  showPengeluaranModal = () => {
    const { transactionData } = this.state;
    const template = [
      {
        id_chart_of_account: "",
        note: "",
        value: "0",
      },
    ];

    this.setState({
      showTransactionOut: true,
      transactionData: {
        ...transactionData,
        normal_balance: "K",
        date: moment().format("YYYY-MM-DD"),
        number:
          "co/" +
          moment().format("YYYY/MM/DD/HHIISS") +
          Math.floor(100 + Math.random() * 1000).toString(),
      },
      details: template,
    });
  };

  showPenerimaanModal = () => {
    const { transactionData } = this.state;
    const template = [
      {
        id_chart_of_account: "",
        note: "",
        value: "0",
      },
    ];

    this.setState({
      showTransactionIn: true,
      transactionData: {
        ...transactionData,
        normal_balance: "D",
        date: moment().format("YYYY-MM-DD"),
        number:
          "ci/" +
          moment().format("YYYY/MM/DD/HHIISS") +
          Math.floor(100 + Math.random() * 1000).toString(),
      },
      details: template,
    });
  };

  addDetail = () => {
    const template = {
      id_chart_of_account: "",
      note: "",
      value: "0",
    };

    const detailTemp = [...this.state.details];
    detailTemp.push(template);

    this.setState({
      details: detailTemp,
    });
  };

  detailHandleChange = (event, index) => {
    const { details } = this.state;

    const updatedDetails = [...details];
    updatedDetails[index][event.target.name] = event.target.value;

    this.setState({
      details: updatedDetails,
    });
  };

  deleteDetail = (index) => {
    const updatedDetails = [...this.state.details];
    updatedDetails.splice(index, 1);
    this.setState({ details: updatedDetails });
  };

  showJournalModal = (selectedTransaction) => {
    this.setState({ showJournal: true, selectedTransaction });
  };

  generatePdf = () => {
    const {
      selectedCashAccount,
      entityName,
      startDate,
      endDate,
      transactionList,
    } = this.state;

    const doc = new jsPDF("l", "pt");

    const title1 = entityName;
    const title2 = "Buku Kas " + selectedCashAccount.name;
    const title3 =
      moment(startDate).format("DD/MM/YYYY") +
      " s/d " +
      moment(endDate).format("DD/MM/YYYY");

    doc.text(
      doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth(title1) * doc.internal.getFontSize()) / 2,
      40,
      title1
    );

    doc.text(
      doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth(title2) * doc.internal.getFontSize()) / 2,
      60,
      title2
    );

    doc.text(
      doc.internal.pageSize.width / 2 -
        (doc.getStringUnitWidth(title3) * doc.internal.getFontSize()) / 2,
      80,
      title3
    );

    let valuesArrObj = [
      [
        "",
        "",
        "Saldo Awal",
        "",
        "",
        numberWithCommas(selectedCashAccount.start_balance),
      ],
    ];
    transactionList.forEach((transaction, index) => {
      valuesArrObj.push([
        moment(transaction.date).format("DD-MMMM-YYYY"),
        transaction.name,
        transaction.note,
        numberWithCommas(transaction.in_value),
        numberWithCommas(transaction.out_value),
        numberWithCommas(transaction.balance),
      ]);
    });

    autoTable(doc, {
      startY: 100,
      columnStyles: {
        3: { halign: "right" },
        4: { halign: "right" },
        5: { halign: "right" },
      },
      head: [["Tanggal", "Akun", "Keterangan", "Masuk", "Keluar", "Saldo"]],
      body: valuesArrObj,
    });

    doc.save(title1 + " " + title2 + " " + title3 + ".pdf");
  };

  render() {
    const {
      idEntity,
      searchText,
      cashAccounts,
      selectedCashAccount,
      startDate,
      endDate,
      transactionList,
    } = this.state;
    const {
      getEntityResult,
      getEntityLoading,
      getCashAccountLoading,
      getTransactionLoading,
    } = this.props;
    const headerCardStyle = {
      background: "#0674e2",
      textAlign: "center",
      color: "white",
    };

    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Buku Kas</CardTitle>
                </CardHeader>
                <CardBody>
                  {getEntityLoading ? (
                    <SpinnerRow />
                  ) : (
                    <>
                      <Row>
                        <Col md="3">
                          <Input
                            type="select"
                            name="idEntity"
                            onChange={(event) => this.handleSelectChange(event)}
                            value={idEntity}
                            options={getEntityResult.data}
                            style={{ display: "block", margin: "auto" }}
                          >
                            <option value="">--Pilih Entitas--</option>
                            {getEntityResult &&
                              getEntityResult.data.map((entity, index) => (
                                <option key={index} value={entity.id}>
                                  {entity.entity_name}
                                </option>
                              ))}
                          </Input>
                        </Col>
                        <Col>
                          <Button
                            color="success me-2"
                            disabled={getEntityLoading}
                            onClick={this.getDefaultData}
                          >
                            {searchText}
                          </Button>
                        </Col>
                      </Row>
                    </>
                  )}

                  {cashAccounts ? (
                    <>
                      <hr />
                      <Row>
                        {cashAccounts.map((account, index) => (
                          <Col
                            md="3"
                            key={index}
                            onClick={() => this.selectCashAccount(index)}
                          >
                            <Card>
                              <CardHeader style={headerCardStyle}>
                                <CardTitle tag="h5">{account.name}</CardTitle>
                              </CardHeader>
                              <CardBody
                                style={{
                                  textAlign: "center",
                                }}
                              >
                                {numberWithCommas(account.start_balance)}
                              </CardBody>
                            </Card>
                          </Col>
                        ))}
                      </Row>

                      <hr />
                    </>
                  ) : getCashAccountLoading ? (
                    <SpinnerRow />
                  ) : (
                    ""
                  )}

                  {selectedCashAccount && (
                    <>
                      <Row>
                        <Col md="12">
                          <Card id="test-content">
                            <CardHeader style={headerCardStyle}>
                              <CardTitle tag="h4">
                                {selectedCashAccount.name}
                              </CardTitle>
                            </CardHeader>
                            <CardBody>
                              <Table borderless>
                                <tbody>
                                  {getEntityLoading ? (
                                    <SpinnerTable />
                                  ) : (
                                    <>
                                      <tr>
                                        <td
                                          width="8%"
                                          style={{ verticalAlign: "middle" }}
                                        >
                                          Tanggal Awal
                                        </td>
                                        <td width="20%">
                                          <Input
                                            type="date"
                                            value={startDate}
                                            name="startDate"
                                            onChange={(event) =>
                                              this.handleChange(event)
                                            }
                                          />
                                        </td>
                                        <td></td>
                                        <td width="20%"></td>
                                        <td width="20%" align="center">
                                          <Button
                                            color="success me-2"
                                            onClick={this.showPenerimaanModal}
                                          >
                                            Penerimaan Kas
                                          </Button>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style={{ verticalAlign: "middle" }}>
                                          Tanggal Akhir
                                        </td>
                                        <td>
                                          <Input
                                            type="date"
                                            value={endDate}
                                            name="endDate"
                                            onChange={(event) =>
                                              this.handleChange(event)
                                            }
                                          />
                                        </td>
                                        <td>
                                          <Button
                                            color="warning me-2"
                                            onClick={this.getTransactionData}
                                          >
                                            Tampilkan
                                          </Button>
                                          {transactionList && (
                                            <Button
                                              color="primary me-2"
                                              onClick={this.generatePdf}
                                            >
                                              Cetak PDF
                                            </Button>
                                          )}
                                        </td>
                                        <td></td>
                                        <td align="center">
                                          <Button
                                            color="danger me-2"
                                            onClick={this.showPengeluaranModal}
                                          >
                                            Pengeluaran Kas
                                          </Button>
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                </tbody>
                              </Table>
                              <Table responsive bordered>
                                <thead className="text-primary">
                                  <tr>
                                    <th>Tanggal</th>
                                    <th>Akun</th>
                                    <th>Keterangan</th>
                                    <th width="10%">Masuk</th>
                                    <th width="10%">Keluar</th>
                                    <th width="10%">Saldo</th>
                                    <th width="5%" className="text-center">
                                      *
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {transactionList && (
                                    <tr>
                                      <td></td>
                                      <td></td>
                                      <td>Saldo Awal</td>
                                      <td></td>
                                      <td></td>
                                      <td align="right">
                                        {numberWithCommas(
                                          selectedCashAccount.start_balance
                                        )}
                                      </td>
                                      <td></td>
                                    </tr>
                                  )}

                                  {transactionList ? (
                                    transactionList.map(
                                      (transaction, index) => (
                                        <tr key={index}>
                                          <td>
                                            {moment(transaction.date).format(
                                              "DD-MMMM-YYYY"
                                            )}
                                          </td>
                                          <td>{transaction.name}</td>
                                          <td>{transaction.note}</td>
                                          <td align="right">
                                            {numberWithCommas(
                                              transaction.in_value
                                            )}
                                          </td>
                                          <td align="right">
                                            {numberWithCommas(
                                              transaction.out_value
                                            )}
                                          </td>
                                          <td align="right">
                                            {numberWithCommas(
                                              transaction.balance
                                            )}
                                          </td>
                                          <td align="center">
                                            <FontAwesomeIcon
                                              icon={faMagnifyingGlass}
                                              className="ms-auto"
                                              onClick={() =>
                                                this.showJournalModal(
                                                  transaction
                                                )
                                              }
                                            />
                                          </td>
                                        </tr>
                                      )
                                    )
                                  ) : getTransactionLoading ? (
                                    <SpinnerTable colSpan={7} />
                                  ) : (
                                    <EmptyData colSpan={7} />
                                  )}
                                </tbody>
                              </Table>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <TransactionOutModal
          show={this.state.showTransactionOut}
          handleClose={() => this.setState({ showTransactionOut: false })}
          handleSubmit={this.transactionHandleSubmit}
          transactionData={this.state.transactionData}
          details={this.state.details}
          handleChange={this.handleChangeTransaction}
          selectedCashAccount={selectedCashAccount}
          detailHandleChange={this.detailHandleChange}
          deleteDetail={this.deleteDetail}
          addDetail={this.addDetail}
        />

        <TransactionInModal
          show={this.state.showTransactionIn}
          handleClose={() => this.setState({ showTransactionIn: false })}
          handleSubmit={this.transactionHandleSubmit}
          transactionData={this.state.transactionData}
          details={this.state.details}
          handleChange={this.handleChangeTransaction}
          selectedCashAccount={selectedCashAccount}
          detailHandleChange={this.detailHandleChange}
          deleteDetail={this.deleteDetail}
          addDetail={this.addDetail}
        />

        <AccountJournalModal
          show={this.state.showJournal}
          handleClose={() => this.setState({ showJournal: false })}
          selectedTransaction={this.state.selectedTransaction}
          selectedCashAccount={selectedCashAccount}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  getEntityLoading: state.EntityReducer.getEntityLoading,
  getEntityResult: state.EntityReducer.getEntityResult,
  getEntityError: state.EntityReducer.getEntityError,

  getCashAccountGroupLoading:
    state.CashAccountReducer.getCashAccountGroupLoading,
  getCashAccountGroupResult: state.CashAccountReducer.getCashAccountGroupResult,
  getCashAccountGroupError: state.CashAccountReducer.getCashAccountGroupError,

  getCashAccountLoading: state.CashAccountReducer.getCashAccountLoading,
  getCashAccountResult: state.CashAccountReducer.getCashAccountResult,
  getCashAccountError: state.CashAccountReducer.getCashAccountError,

  getCashInAccountLoading: state.AccountReducer.getCashInAccountLoading,
  getCashInAccountResult: state.AccountReducer.getCashInAccountResult,
  getCashInAccountError: state.AccountReducer.getCashInAccountError,

  addTransactionLoading: state.TransactionReducer.addTransactionLoading,
  addTransactionResult: state.TransactionReducer.addTransactionResult,
  addTransactionError: state.TransactionReducer.addTransactionError,

  getTransactionLoading: state.TransactionReducer.getTransactionLoading,
  getTransactionResult: state.TransactionReducer.getTransactionResult,
  getTransactionError: state.TransactionReducer.getTransactionError,
});

export default connect(mapStateToProps, null)(CashBook);
