import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment/moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  FormGroup,
  Input,
  InputGroup,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import swal from "sweetalert";
import { getEntityList } from "../../../actions/EntityAction";
import { getInventoryList } from "../../../actions/InventoryAction";
import {
  addInventoryMutation,
  getInventoryMutationAllList,
  getInventoryMutationList,
} from "../../../actions/InventoryMutationAction";
import InventoryMutationModal from "../../../components/private/Inventory/InventoryMutationModal";
import InventoryListModal from "../../../components/private/Inventory/InventoryListModal";
import SpinnerTable from "../../../components/spinner/SpinnerTable";
import { numberWithCommas } from "../../../utils";

class InventoryMutation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idEntity: "",
      inventories: false,
      inventoryHistories: false,
      inventoryHistoryAll: false,
      startInventoryHistoryBalance: false,
      searchText: "Cari",
      startDate: "",
      endDate: "",
      showInventoryModal: false,
      selectedInventory: false,
      addedInventory: false,
      showInventoryListModal: false,
    };
  }

  handleSelectChange = (event) => {
    const value = event.target.value;
    this.setState({
      idEntity: value,
      searchText: "Cari",
      inventoryHistories: false,
      inventories: false,
      selectedInventory: false,
    });
    if (value) {
      this.props.dispatch(getInventoryList(value));
    }
  };

  componentDidMount() {
    this.props.dispatch(getEntityList());
  }

  componentDidUpdate(prevProps) {
    const {
      getInventoryResult,
      getInventoryMutationAllResult,
      getInventoryMutationResult,
      addInventoryMutationResult,
      getInventoryError,
      addInventoryMutationError,
      getInventoryMutationError,
      getInventoryMutationAllError,
    } = this.props;

    if (
      getInventoryResult &&
      prevProps.getInventoryResult !== getInventoryResult
    ) {
      this.setState({
        inventories: getInventoryResult.data,
        addedInventory: false,
        selectedInventory: false,
        showInventoryModal: false,
      });
    }

    if (
      getInventoryMutationResult &&
      prevProps.getInventoryMutationResult !== getInventoryMutationResult
    ) {
      this.setInventoryMutationBalance(getInventoryMutationResult.data);
    }

    if (
      getInventoryMutationAllResult &&
      prevProps.getInventoryMutationAllResult !== getInventoryMutationAllResult
    ) {
      this.setState({
        inventoryHistoryAll: getInventoryMutationAllResult.data,
      });
    }

    if (
      addInventoryMutationResult &&
      prevProps.addInventoryMutationResult !== addInventoryMutationResult
    ) {
      swal("Sukses", addInventoryMutationResult.messages, "success");
      this.setState({
        addedInventory: false,
        showInventoryModal: false,
      });
      this.getInventoryMutationData();
    }

    if (
      getInventoryError &&
      prevProps.getInventoryError !== getInventoryError
    ) {
      swal("Error", getInventoryError.messages.error, "error");
    }

    if (
      addInventoryMutationError &&
      prevProps.addInventoryMutationError !== addInventoryMutationError
    ) {
      swal("Error", addInventoryMutationError.messages.error, "error");
    }

    if (
      getInventoryMutationError &&
      prevProps.getInventoryMutationError !== getInventoryMutationError
    ) {
      swal("Error", getInventoryMutationError.messages.error, "error");
    }

    if (
      getInventoryMutationAllError &&
      prevProps.getInventoryMutationAllError !== getInventoryMutationAllError
    ) {
      swal("Error", getInventoryMutationAllError.messages.error, "error");
    }
  }

  setInventoryMutationBalance = (data) => {
    const { startInventoryHistoryBalance } = this.state;

    let inventoryHistoryTemp = data;

    let grandPrice = startInventoryHistoryBalance
      ? parseFloat(startInventoryHistoryBalance.grand_prince)
      : 0;
    let grandAmount = startInventoryHistoryBalance
      ? parseFloat(startInventoryHistoryBalance.grand_amount)
      : 0;
    let grandTotal = startInventoryHistoryBalance
      ? parseFloat(startInventoryHistoryBalance.grand_total)
      : 0;
    // console.log(inventoryHistoryAllTemp);
    inventoryHistoryTemp.forEach((detail) => {
      if (detail.type === "out") {
        grandAmount = grandAmount - parseFloat(detail.amount);
        grandTotal = grandTotal - parseFloat(detail.amount) * grandPrice;
        detail.price = grandPrice;
        detail.total = grandPrice * parseFloat(detail.amount);
        // grandPrice = grandAmount * grandPrice;
      } else {
        grandAmount = grandAmount + parseFloat(detail.amount);
        grandTotal =
          grandTotal + parseFloat(detail.amount) * parseFloat(detail.price);
        grandPrice = grandTotal / grandAmount;
      }

      detail.grand_total = grandTotal;
      detail.grand_price = grandPrice;
      detail.grand_amount = grandAmount;
    });

    this.setState({
      inventoryHistories: inventoryHistoryTemp,
    });
  };

  setStartInventoryMutationBalance = () => {
    const { inventoryHistoryAll, startDate } = this.state;

    let inventoryHistoryAllTemp = inventoryHistoryAll.filter(
      (i) => moment(i.date).diff(startDate, "days") < 0
    );
    let grandPrice = 0;
    let grandAmount = 0;
    let grandTotal = 0;
    // console.log(inventoryHistoryAllTemp);
    inventoryHistoryAllTemp.forEach((detail) => {
      if (detail.type === "out") {
        grandAmount = grandAmount - parseFloat(detail.amount);
        grandTotal = grandTotal - parseFloat(detail.amount) * grandPrice;
        // grandPrice = grandAmount * grandPrice;
      } else {
        grandAmount = grandAmount + parseFloat(detail.amount);
        grandTotal =
          grandTotal + parseFloat(detail.amount) * parseFloat(detail.price);
        grandPrice = grandTotal / grandAmount;
      }

      detail.grand_total = grandTotal;
      detail.grand_price = grandPrice;
      detail.grand_amount = grandAmount;
    });

    this.setState({
      startInventoryHistoryBalance: inventoryHistoryAllTemp
        ? inventoryHistoryAllTemp[inventoryHistoryAllTemp.length - 1]
        : false,
    });
  };

  getData = () => {
    const { idEntity, startDate, endDate, selectedInventory } = this.state;
    if (idEntity && selectedInventory && startDate && endDate) {
      this.setState({
        inventoryMutation: false,
        startInventoryHistoryBalance: false,
      });
      this.setStartInventoryMutationBalance();
      this.getInventoryMutationData();
    } else
      swal(
        "Error",
        "Pilih entitas serta isi tanggal awal dan akhir data yang akan ditampilkan!",
        "error"
      );
  };

  getInventoryMutationData = () => {
    const { idEntity, startDate, endDate, selectedInventory } = this.state;
    this.props.dispatch(
      getInventoryMutationList(
        idEntity,
        selectedInventory.id,
        startDate,
        endDate
      )
    );
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  setAddedInventory = (event) => {
    const { addedInventory } = this.state;

    const tempInventoryMutation = addedInventory;
    tempInventoryMutation[event.target.name] = event.target.value;

    this.setState({
      addedInventory: tempInventoryMutation,
    });
  };

  setSelectedInventory = (selectedInventory) => {
    const { idEntity } = this.state;
    this.setState({ selectedInventory, showInventoryListModal: false });
    this.props.dispatch(
      getInventoryMutationAllList(idEntity, selectedInventory.id)
    );
  };

  addInventoryMutation = (event) => {
    const { addedInventory, idEntity, selectedInventory } = this.state;
    event.preventDefault();

    if (addedInventory) {
      const tempInventoryMutation = addedInventory;
      tempInventoryMutation["id_entity"] = idEntity;
      tempInventoryMutation["id_inventory"] = selectedInventory.id;
      this.setState({
        addedInventory: tempInventoryMutation,
      });

      this.props.dispatch(addInventoryMutation(addedInventory));
    } else {
      swal("Error", "Isi semua kolom yang dibutuhkan!!", "error");
    }
  };

  render() {
    const {
      idEntity,
      searchText,
      inventoryHistories,
      startDate,
      endDate,
      inventories,
      selectedInventory,
      startInventoryHistoryBalance,
    } = this.state;
    const {
      getEntityLoading,
      getEntityResult,
      getInventoryMutationLoading,
      addInventoryMutationLoading,
      getInventoryLoading,
    } = this.props;
    // console.log(getInventoryMutationAllLoading);
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Laporan Mutasi Persediaan</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table borderless>
                    <tbody>
                      {getEntityLoading ? (
                        <tr>
                          <td className="text-center">
                            <Spinner color="primary" />
                          </td>
                        </tr>
                      ) : (
                        <>
                          <tr>
                            <td width="20%">
                              <Input
                                type="select"
                                name="idEntity"
                                onChange={(event) =>
                                  this.handleSelectChange(event)
                                }
                                value={idEntity}
                                options={getEntityResult.data}
                                style={{ display: "block", margin: "auto" }}
                              >
                                <option value="">--Pilih Entitas--</option>
                                {getEntityResult &&
                                  getEntityResult.data.map((entity, index) => (
                                    <option key={index} value={entity.id}>
                                      {entity.entity_name}
                                    </option>
                                  ))}
                              </Input>
                            </td>
                            <td width="2%"></td>

                            {inventories && (
                              <td width="20%">
                                <FormGroup className="float-right">
                                  <InputGroup>
                                    <Input
                                      type="text"
                                      value={
                                        selectedInventory &&
                                        selectedInventory.code
                                          ? selectedInventory.code
                                          : ""
                                      }
                                      disabled={true}
                                      placeholder="Kode Persediaan"
                                    />
                                    <Button
                                      color="info"
                                      onClick={() =>
                                        this.setState({
                                          showInventoryListModal: true,
                                          inventoryHistories: false,
                                          startInventoryHistoryBalance: false,
                                        })
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faSearch}
                                        className="ms-auto"
                                      />
                                    </Button>
                                  </InputGroup>
                                </FormGroup>
                              </td>
                            )}
                            {getInventoryLoading && (
                              <td className="text-center" width="20%">
                                <Spinner color="primary" />
                              </td>
                            )}

                            <td></td>
                            <td width="20%" align="center">
                              {selectedInventory && (
                                <Button
                                  color="success me-2"
                                  disabled={!inventories}
                                  onClick={() =>
                                    this.setState({
                                      showInventoryModal: true,
                                      addedInventory: {
                                        type: "in",
                                        amount: "0",
                                        price: "0",
                                      },
                                    })
                                  }
                                >
                                  Perolehan Persediaan
                                </Button>
                              )}
                            </td>
                          </tr>
                          {selectedInventory && (
                            <>
                              <tr>
                                <td>
                                  <Input
                                    type="date"
                                    value={startDate}
                                    name="startDate"
                                    onChange={(event) =>
                                      this.handleChange(event)
                                    }
                                  />
                                </td>
                                <td style={{ verticalAlign: "middle" }}>sd</td>
                                <td>
                                  <Input
                                    type="date"
                                    value={endDate}
                                    name="endDate"
                                    onChange={(event) =>
                                      this.handleChange(event)
                                    }
                                  />
                                </td>
                                <td>
                                  <Button
                                    color="success me-2"
                                    disabled={
                                      getEntityLoading ||
                                      !idEntity ||
                                      !startDate ||
                                      !endDate
                                    }
                                    onClick={this.getData}
                                  >
                                    {searchText}
                                  </Button>
                                </td>
                                <td align="center">
                                  <Button
                                    color="danger me-2"
                                    disabled={!inventories}
                                    onClick={() =>
                                      this.setState({
                                        showInventoryModal: true,
                                        addedInventory: {
                                          type: "out",
                                          amount: "0",
                                          price: "0",
                                        },
                                      })
                                    }
                                  >
                                    Pengeluaran Persediaan
                                  </Button>
                                </td>
                              </tr>
                            </>
                          )}
                        </>
                      )}
                    </tbody>
                  </Table>
                  <Table responsive bordered>
                    <thead className="text-primary">
                      <tr>
                        <th width="13%">Kode Persediaan</th>
                        <th colSpan={9}>{selectedInventory.code}</th>
                      </tr>
                      <tr>
                        <th width="13%">Nama Persediaan</th>
                        <th colSpan={9}>{selectedInventory.name}</th>
                      </tr>
                      <tr>
                        <th>Unit Pengukuran</th>
                        <th colSpan={9}>
                          {selectedInventory.measurement_name}
                        </th>
                      </tr>
                      <tr>
                        <th>Kategori Persediaan</th>
                        <th colSpan={9}>
                          {selectedInventory.inventory_category_name}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td></td>
                        <td colSpan={3} className="text-center">
                          Perolehan
                        </td>
                        <td colSpan={3} className="text-center">
                          Pengeluaran
                        </td>
                        <td colSpan={3} className="text-center">
                          Saldo
                        </td>
                      </tr>
                      <tr>
                        <td className="text-center">Tanggal</td>
                        <td className="text-center">Harga</td>
                        <td className="text-center">Jumlah</td>
                        <td className="text-center">Total</td>
                        <td className="text-center">Harga</td>
                        <td className="text-center">Jumlah</td>
                        <td className="text-center">Total</td>
                        <td className="text-center">Harga</td>
                        <td className="text-center">Jumlah</td>
                        <td className="text-center">Total</td>
                      </tr>
                      <tr>
                        <td colSpan={7} className="text-center">
                          Saldo Awal
                        </td>
                        <td className="text-end">
                          {startInventoryHistoryBalance
                            ? numberWithCommas(
                                Math.round(
                                  startInventoryHistoryBalance.grand_price
                                )
                              )
                            : 0}
                        </td>
                        <td className="text-end">
                          {startInventoryHistoryBalance
                            ? numberWithCommas(
                                Math.round(
                                  startInventoryHistoryBalance.grand_amount
                                )
                              )
                            : 0}
                        </td>
                        <td className="text-end">
                          {startInventoryHistoryBalance
                            ? numberWithCommas(
                                Math.round(
                                  startInventoryHistoryBalance.grand_total
                                )
                              )
                            : 0}
                        </td>
                      </tr>

                      {!addInventoryMutationLoading &&
                      inventoryHistories &&
                      inventoryHistories.length > 0 ? (
                        inventoryHistories.map((inventoryMutation, index) => (
                          <tr key={index}>
                            <td className="text-center">
                              {moment(inventoryMutation.date).format(
                                "DD MMM YYYY"
                              )}
                            </td>
                            {inventoryMutation.type === "in" ? (
                              <>
                                <td className="text-end">
                                  {numberWithCommas(
                                    Math.round(inventoryMutation.price)
                                  )}
                                </td>
                                <td className="text-end">
                                  {numberWithCommas(
                                    Math.round(inventoryMutation.amount)
                                  )}
                                </td>
                                <td className="text-end">
                                  {numberWithCommas(
                                    Math.round(inventoryMutation.total)
                                  )}
                                </td>
                              </>
                            ) : (
                              <>
                                <td></td>
                                <td></td>
                                <td></td>
                              </>
                            )}
                            {inventoryMutation.type === "out" ? (
                              <>
                                <td className="text-end">
                                  {numberWithCommas(
                                    Math.round(inventoryMutation.price)
                                  )}
                                </td>
                                <td className="text-end">
                                  {numberWithCommas(
                                    Math.round(inventoryMutation.amount)
                                  )}
                                </td>
                                <td className="text-end">
                                  {numberWithCommas(
                                    Math.round(inventoryMutation.total)
                                  )}
                                </td>
                              </>
                            ) : (
                              <>
                                <td></td>
                                <td></td>
                                <td></td>
                              </>
                            )}
                            <td className="text-end">
                              {numberWithCommas(
                                Math.round(inventoryMutation.grand_price)
                              )}
                            </td>
                            <td className="text-end">
                              {numberWithCommas(
                                Math.round(inventoryMutation.grand_amount)
                              )}
                            </td>
                            <td className="text-end">
                              {numberWithCommas(
                                Math.round(inventoryMutation.grand_total)
                              )}
                            </td>
                          </tr>
                        ))
                      ) : getInventoryMutationLoading ||
                        addInventoryMutationLoading ? (
                        <SpinnerTable colSpan={10} />
                      ) : (
                        <>
                          <tr>
                            <td colSpan={10}></td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        <InventoryMutationModal
          show={this.state.showInventoryModal}
          handleClose={() => this.setState({ showInventoryModal: false })}
          addedInventory={this.state.addedInventory}
          addInventoryMutation={this.addInventoryMutation}
          inventories={inventories}
          setAddedInventory={this.setAddedInventory}
          selectedInventory={this.state.selectedInventory}
          inventoryHistoryAll={this.state.inventoryHistoryAll}
        />

        <InventoryListModal
          show={this.state.showInventoryListModal}
          handleClose={() => this.setState({ showInventoryListModal: false })}
          inventories={inventories}
          setSelectedInventory={this.setSelectedInventory}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  getEntityLoading: state.EntityReducer.getEntityLoading,
  getEntityResult: state.EntityReducer.getEntityResult,
  getEntityError: state.EntityReducer.getEntityError,

  getInventoryMutationLoading:
    state.InventoryMutationReducer.getInventoryMutationLoading,
  getInventoryMutationResult:
    state.InventoryMutationReducer.getInventoryMutationResult,
  getInventoryMutationError:
    state.InventoryMutationReducer.getInventoryMutationError,

  getInventoryMutationAllLoading:
    state.InventoryMutationReducer.getInventoryMutationAllLoading,
  getInventoryMutationAllResult:
    state.InventoryMutationReducer.getInventoryMutationAllResult,
  getInventoryMutationAllError:
    state.InventoryMutationReducer.getInventoryMutationAllError,

  addInventoryMutationLoading:
    state.InventoryMutationReducer.addInventoryMutationLoading,
  addInventoryMutationResult:
    state.InventoryMutationReducer.addInventoryMutationResult,
  addInventoryMutationError:
    state.InventoryMutationReducer.addInventoryMutationError,

  getInventoryLoading: state.InventoryReducer.getInventoryLoading,
  getInventoryResult: state.InventoryReducer.getInventoryResult,
  getInventoryError: state.InventoryReducer.getInventoryError,
});

export default connect(mapStateToProps, null)(InventoryMutation);
