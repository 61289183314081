import axios from "axios";
import { API_URL } from "../constants";
import { getLocalStorage } from "../localStorage";

export const axiosInstance = axios.create({
  baseURL: API_URL,
  //   headers: { Authorization: getAuthorizationHeader() },
});

export const getAuthorizationHeader = () => {
  return {
    "Content-Type": "application/json",
    Authorization: getLocalStorage("user").token,
  };
};
