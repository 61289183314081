import {
  dispatchError,
  dispatchLoading,
  dispatchSuccess,
  getAuthorizationHeader,
} from "../utils";
import axios from "axios";

export const GET_ACCOUNT_LIST = "GET_ACCOUNT_LIST";
export const GET_SPECIFIC_ACCOUNT_LIST = "GET_SPECIFIC_ACCOUNT_LIST";
export const GET_CASH_IN_ACCOUNT_LIST = "GET_CASH_IN_ACCOUNT_LIST";
export const GET_CASH_OUT_ACCOUNT_LIST = "GET_CASH_OUT_ACCOUNT_LIST";
export const EDIT_ACCOUNT = "EDIT_ACCOUNT";
export const ADD_ACCOUNT = "ADD_ACCOUNT";
export const DELETE_ACCOUNT = "DELETE_ACCOUNT";

export const getAccountList = (idEntity) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_ACCOUNT_LIST);
    axios({
      method: "GET",
      url: "account?id_entity=" + idEntity,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, GET_ACCOUNT_LIST, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, GET_ACCOUNT_LIST, error.response.data);
      });
  };
};

export const getCashInAccountList = (idEntity) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_CASH_IN_ACCOUNT_LIST);
    axios({
      method: "GET",
      url: "cash-affected-account?id_entity=" + idEntity + "&cash=in",
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, GET_CASH_IN_ACCOUNT_LIST, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, GET_CASH_IN_ACCOUNT_LIST, error.response.data);
      });
  };
};

export const getCashOutAccountList = (idEntity) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_CASH_OUT_ACCOUNT_LIST);
    axios({
      method: "GET",
      url: "cash-affected-account?id_entity=" + idEntity + "&cash=out",
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, GET_CASH_OUT_ACCOUNT_LIST, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, GET_CASH_OUT_ACCOUNT_LIST, error.response.data);
      });
  };
};

export const getSpecificAccountList = (idEntity, groupAccountType) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_SPECIFIC_ACCOUNT_LIST);
    axios({
      method: "GET",
      url:
        "specific-account?id_entity=" +
        idEntity +
        "&group_account_type=" +
        groupAccountType,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, GET_SPECIFIC_ACCOUNT_LIST, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, GET_SPECIFIC_ACCOUNT_LIST, error.response.data);
      });
  };
};

export const AddAccount = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, ADD_ACCOUNT);
    axios({
      method: "POST",
      url: "add-account",
      data,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, ADD_ACCOUNT, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, ADD_ACCOUNT, error.response.data);
      });
  };
};

export const editAccount = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, EDIT_ACCOUNT);
    axios({
      method: "PUT",
      url: "update-account",
      data,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, EDIT_ACCOUNT, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, EDIT_ACCOUNT, error.response.data);
      });
  };
};
export const deleteAccount = (id) => {
  return (dispatch) => {
    dispatchLoading(dispatch, DELETE_ACCOUNT);
    axios({
      method: "DELETE",
      url: "account/" + id,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, DELETE_ACCOUNT, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, DELETE_ACCOUNT, error.response.data);
      });
  };
};
