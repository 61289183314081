import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Table } from "reactstrap";
import { PaginationComponent, SearchComponent } from "../../Table";
import EmptyData from "../../Table/EmptyData";

export default function InventoryListModal({
  show,
  handleClose,
  inventories,
  setSelectedInventory,
}) {
  const pageSize = 10;
  const pagesCount = Math.ceil(inventories.length / pageSize);

  const totalData = inventories ? inventories.length : 0;
  const [keyword, setKeyword] = useState("");
  const [searchedText, setSearchedText] = useState("");
  const [currentPage, setCurrentPage] = useState(0);

  const filterHandleSubmit = (event) => {
    event.preventDefault();
    setSearchedText(keyword);
    setCurrentPage(0);
  };

  const handleChange = (event) => {
    setKeyword(event.target.value);
  };

  const handleClick = (e, index) => {
    e.preventDefault();
    setCurrentPage(index);
  };

  return (
    <Modal show={show} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>List Persediaan</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table borderless>
          <tbody>
            <tr>
              <td className="float-end">
                <SearchComponent
                  keyword={keyword}
                  searchedText={searchedText}
                  keywordHandleSubmit={filterHandleSubmit}
                  handleChange={handleChange}
                />
              </td>
            </tr>
          </tbody>
        </Table>
        <Table responsive>
          <thead className="text-primary">
            <tr>
              <th width="5%">No</th>
              <th width="10%">Kode</th>
              <th>Nama</th>
              <th width="15%">Unit Pengukuran</th>
              <th width="15%">Kategori</th>
              <th width="20%">Akun</th>
              <th width="5%" className="text-center">
                *
              </th>
            </tr>
          </thead>
          <tbody>
            {inventories && inventories.length > 0 ? (
              inventories
                .filter((inventory) =>
                  inventory.code
                    .toLowerCase()
                    .includes(searchedText.toLowerCase()) ||
                  inventory.name
                    .toLowerCase()
                    .includes(searchedText.toLowerCase())
                    ? true
                    : false
                )
                .slice(currentPage * pageSize, (currentPage + 1) * pageSize)
                .map((inventory, index) => (
                  <tr key={index}>
                    <td className="align-middle">
                      {currentPage * pageSize + index + 1}
                    </td>
                    <td className="align-middle">{inventory.code}</td>
                    <td className="align-middle">{inventory.name}</td>
                    <td className="align-middle">
                      {inventory.measurement_name}
                    </td>
                    <td className="align-middle">
                      {inventory.inventory_category_name}
                    </td>
                    <td className="align-middle">
                      {inventory.chart_of_account_name}
                    </td>
                    <td className="text-center">
                      <Button
                        color="warning"
                        onClick={() => {
                          setSelectedInventory(inventory);
                          handleClose();
                        }}
                      >
                        <FontAwesomeIcon icon={faCheck} className="ms-auto" />
                      </Button>
                    </td>
                  </tr>
                ))
            ) : (
              <EmptyData colSpan={7} />
            )}
          </tbody>
        </Table>
        {!searchedText && (
          <>
            <br />
            <PaginationComponent
              currentPage={currentPage}
              pagesCount={pagesCount}
              totalData={totalData}
              pageSize={pageSize}
              handleClick={handleClick}
            />
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
