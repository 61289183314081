import moment from "moment";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { Button, Col, Input, Row, Spinner, Table } from "reactstrap";
import {
  checkNumberWithCommas,
  convertToNumber,
  numberWithCommas,
} from "../../../utils";
import SpinnerTable from "../../spinner/SpinnerTable";

export default function InventoryMutationModal({
  show,
  handleClose,
  addInventoryMutation,
  addedInventory,
  setAddedInventory,
  selectedInventory,
  inventoryHistoryAll,
}) {
  const { addInventoryMutationLoading } = useSelector(
    (state) => state.InventoryMutationReducer
  );

  const [startBalance, setStartBalance] = useState(false);

  const calculateStartBalance = (event) => {
    let inventoryHistoryAllTemp = inventoryHistoryAll.filter(
      (i) => moment(i.date).diff(event.target.value, "days") <= 0
    );
    let grandPrice = 0;
    let grandAmount = 0;
    let grandTotal = 0;
    // console.log(inventoryHistoryAllTemp);
    inventoryHistoryAllTemp.forEach((detail) => {
      if (detail.type === "out") {
        grandAmount = grandAmount - parseFloat(detail.amount);
        grandTotal = grandTotal - parseFloat(detail.amount) * grandPrice;
        // grandPrice = grandAmount * grandPrice;
      } else {
        grandAmount = grandAmount + parseFloat(detail.amount);
        grandTotal =
          grandTotal + parseFloat(detail.amount) * parseFloat(detail.price);
        grandPrice = grandTotal / grandAmount;
      }

      detail.grand_total = grandTotal;
      detail.grand_price = grandPrice;
      detail.grand_amount = grandAmount;
    });

    setStartBalance(
      inventoryHistoryAllTemp
        ? inventoryHistoryAllTemp[inventoryHistoryAllTemp.length - 1]
        : false
    );
  };

  return (
    <>
      <Modal show={show} onHide={handleClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>
            {addedInventory && addedInventory.type === "in"
              ? "Perolehan Persediaan"
              : "Pengeluaran Persediaan"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={addInventoryMutation}>
            <Table borderless>
              <tbody>
                {addInventoryMutationLoading ? (
                  <SpinnerTable colSpan={5} />
                ) : (
                  <>
                    <tr>
                      <td width="15%" style={{ verticalAlign: "middle" }}>
                        Tanggal
                      </td>
                      <td width="30%">
                        <Input
                          type="date"
                          value={
                            addedInventory && addedInventory.date
                              ? addedInventory.date
                              : ""
                          }
                          name="date"
                          autoComplete="off"
                          onChange={(event) => {
                            setAddedInventory(event);
                            calculateStartBalance(event);
                          }}
                        />
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td style={{ verticalAlign: "middle" }}>
                        Kode Persediaan
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={
                            selectedInventory && selectedInventory.code
                              ? selectedInventory.code
                              : ""
                          }
                          disabled={true}
                        />
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        Nama Persediaan
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={
                            selectedInventory && selectedInventory.name
                              ? selectedInventory.name
                              : ""
                          }
                          disabled={true}
                        />
                      </td>
                      <td></td>
                    </tr>
                    <tr>
                      <td style={{ verticalAlign: "middle" }}>Kategori</td>
                      <td>
                        <Input
                          type="text"
                          value={
                            selectedInventory &&
                            selectedInventory.inventory_category_name
                              ? selectedInventory.inventory_category_name
                              : ""
                          }
                          disabled={true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td width="15%" style={{ verticalAlign: "middle" }}>
                        {addedInventory && addedInventory.type === "in"
                          ? "Perolehan"
                          : "Pengeluaran"}
                      </td>
                      <td width="30%">
                        <Input
                          type="text"
                          value={
                            addedInventory && addedInventory.amount
                              ? checkNumberWithCommas(addedInventory.amount)
                              : "0"
                          }
                          name="amount"
                          autoComplete="off"
                          onChange={(event) => setAddedInventory(event)}
                        />
                      </td>
                      <td style={{ verticalAlign: "middle" }}>Satuan</td>
                      <td>
                        <Input
                          type="text"
                          value={
                            selectedInventory &&
                            selectedInventory.measurement_name
                              ? selectedInventory.measurement_name
                              : ""
                          }
                          disabled={true}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td width="15%" style={{ verticalAlign: "middle" }}>
                        Harga Satuan
                      </td>
                      <td width="30%">
                        {addedInventory && addedInventory.type === "in" ? (
                          <Input
                            type="text"
                            value={
                              addedInventory && addedInventory.price
                                ? checkNumberWithCommas(addedInventory.price)
                                : "0"
                            }
                            name="price"
                            autoComplete="off"
                            onChange={(event) => setAddedInventory(event)}
                          />
                        ) : (
                          <Input
                            type="text"
                            value={
                              startBalance
                                ? numberWithCommas(
                                    Math.round(startBalance.grand_price)
                                  )
                                : "0"
                            }
                            name="price"
                            disabled={true}
                          />
                        )}
                      </td>
                      <td style={{ verticalAlign: "middle" }}>Total</td>
                      <td>
                        <Input
                          type="text"
                          value={
                            addedInventory &&
                            addedInventory.amount &&
                            addedInventory.price
                              ? addedInventory && addedInventory.type === "in"
                                ? numberWithCommas(
                                    convertToNumber(addedInventory.price) *
                                      convertToNumber(addedInventory.amount)
                                  )
                                : numberWithCommas(
                                    convertToNumber(
                                      Math.round(startBalance.grand_price)
                                    ) * convertToNumber(addedInventory.amount)
                                  )
                              : "0"
                          }
                          disabled={true}
                        />
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>

            <Row>
              <Col>
                {addInventoryMutationLoading ? (
                  <Button color="primary" disabled>
                    <Spinner size="sm" color="light" /> Loading
                  </Button>
                ) : (
                  <>
                    <Button color="primary">Submit</Button>
                    <Button color="warning" onClick={handleClose}>
                      Cancel
                    </Button>
                  </>
                )}
              </Col>
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}
