import React from "react";
import classNames from "classnames";
import { Container } from "reactstrap";

import Topbar from "./Topbar";
import { Outlet } from "react-router-dom";
import Footer from "./Footer";

const Content = ({ sidebarIsOpen, toggleSidebar }) => (
  <Container
    fluid
    className={
      sidebarIsOpen
        ? classNames("content", {
            "is-open": sidebarIsOpen,
          })
        : classNames("content mt-5", {
            "is-open": sidebarIsOpen,
          })
    }
  >
    <Topbar toggleSidebar={toggleSidebar} sidebarIsOpen={sidebarIsOpen} />

    <div className="card">
      <div className="card-body">
        <Outlet />
        <Footer />
      </div>
    </div>
  </Container>
);

export default Content;
