import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import swal from "sweetalert";
import { getSpecificAccountList } from "../../../actions/AccountAction";
import { getEntityList } from "../../../actions/EntityAction";
import {
  deleteInventory,
  editInventory,
  getInventoryList,
} from "../../../actions/InventoryAction";
import { getInventoryCategoryList } from "../../../actions/InventoryCategoryAction";
import { getMeasurementList } from "../../../actions/MeasurementAction";
import SpinnerTable from "../../../components/spinner/SpinnerTable";
import {
  PaginationComponent,
  SearchComponent,
} from "../../../components/Table";
import EmptyData from "../../../components/Table/EmptyData";

class InventoryList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idEntity: "",
      inventories: false,
      isEditing: false,
      searchText: "Cari",

      searchedText: "",
      keyword: "",

      totalData: 0,
      currentPage: 0,
      pageSize: 10,
      pagesCount: 0,
    };
  }

  componentDidMount() {
    this.props.dispatch(getEntityList());
  }

  componentDidUpdate(prevProps) {
    const {
      getInventoryResult,
      getInventoryError,
      editInventoryResult,
      editInventoryError,
      deleteInventoryResult,
      deleteInventoryError,
    } = this.props;

    if (
      getInventoryResult &&
      prevProps.getInventoryResult !== getInventoryResult
    ) {
      this.setState({
        inventories: getInventoryResult.data,
        totalData: getInventoryResult.data.length,
        pagesCount: Math.ceil(
          getInventoryResult.data.length / this.state.pageSize
        ),
        isEditing: false,
      });
    }

    if (
      editInventoryResult &&
      prevProps.editInventoryResult !== editInventoryResult
    ) {
      this.setState({
        isEditing: false,
      });
      this.props.dispatch(getInventoryList(this.state.idEntity));
      swal("Sukses", editInventoryResult.messages, "success");
    }

    if (
      deleteInventoryResult &&
      prevProps.deleteInventoryResult !== deleteInventoryResult
    ) {
      this.setState({
        isEditing: false,
      });
      this.getData();
      swal("Sukses", deleteInventoryResult.messages, "success");
    }

    if (
      getInventoryError &&
      prevProps.getInventoryError !== getInventoryError
    ) {
      swal("Error", getInventoryError.messages.error, "error");
    }

    if (
      editInventoryError &&
      prevProps.editInventoryError !== editInventoryError
    ) {
      swal("Error", editInventoryError.messages.error, "error");
    }

    if (
      deleteInventoryError &&
      prevProps.deleteInventoryError !== deleteInventoryError
    ) {
      swal("Error", deleteInventoryError.messages.error, "error");
    }
  }

  getData = () => {
    const { idEntity } = this.state;
    if (idEntity) {
      this.props.dispatch(getInventoryList(idEntity));
      this.props.dispatch(getInventoryCategoryList(idEntity));
      this.props.dispatch(getMeasurementList(idEntity));
      this.props.dispatch(getSpecificAccountList(idEntity, "inventory"));
    }
  };

  handleSelectChange = (event) => {
    const value = event.target.value;
    this.setState({
      [event.target.name]: value,
      searchText: "Cari",
      inventories: false,
    });
  };

  addData = () => {
    const { inventories, idEntity } = this.state;
    const template = {
      id: "",
      id_entity: idEntity,
      id_measurement: "",
      id_inventory_category: "",
      id_chart_of_account: "",
      code: "",
      name: "",
    };

    const inventoriesTemp = [...inventories];
    inventoriesTemp.push(template);

    this.setState({
      inventories: inventoriesTemp.sort((a, b) => a.code.localeCompare(b.code)),
    });
  };

  saveChanging = () => {
    const { inventories, isEditing } = this.state;

    if (isEditing) this.props.dispatch(editInventory(inventories));
    else swal("Error", "Tidak ada data yang harus disimpan!!", "error");
  };

  handleChange = (event, index) => {
    const { inventories } = this.state;
    const updatedInventories = [...inventories];
    updatedInventories[index][event.target.name] = event.target.value;

    this.setState({
      inventories: updatedInventories,
      isEditing: true,
    });
  };

  deleteData = (id, index) => {
    const { isEditing, inventories } = this.state;

    if (id) {
      if (isEditing)
        swal(
          "Error",
          "Data sedang diubah, simpan data terlebih dahulu!",
          "error"
        );
      else {
        this.props.dispatch(deleteInventory(id));
      }
    } else {
      const deletedData = [...inventories];
      deletedData.splice(index, 1);
      this.setState({ inventories: deletedData });
    }
  };

  handleChangeText = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  filterHandleSubmit = (event) => {
    const { keyword } = this.state;
    event.preventDefault();
    this.setState({ searchedText: keyword });
  };

  handleClick = (e, index) => {
    e.preventDefault();
    this.setState({
      currentPage: index,
    });
  };

  render() {
    const {
      idEntity,
      searchText,
      inventories,
      keyword,
      searchedText,
      currentPage,
      pageSize,
      pagesCount,
      totalData,
    } = this.state;
    const {
      getEntityLoading,
      getEntityResult,
      getInventoryLoading,
      deleteInventoryLoading,
      editInventoryLoading,
      getMeasurementResult,
      getInventoryCategoryResult,
      getSpecificAccountResult,
      getInventoryCategoryLoading,
      getMeasurementLoading,
      getSpecificAccountLoading,
    } = this.props;

    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Master Persediaan</CardTitle>
              </CardHeader>
              <CardBody>
                <Table borderless>
                  <tbody>
                    {getEntityLoading ? (
                      <tr>
                        <td className="text-center">
                          <Spinner color="primary" />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td width="20%">
                          <Input
                            type="select"
                            name="idEntity"
                            onChange={(event) => this.handleSelectChange(event)}
                            value={idEntity}
                            options={getEntityResult.data}
                            style={{ display: "block", margin: "auto" }}
                          >
                            <option value="">--Pilih Entitas--</option>
                            {getEntityResult &&
                              getEntityResult.data.map((entity, index) => (
                                <option key={index} value={entity.id}>
                                  {entity.entity_name}
                                </option>
                              ))}
                          </Input>
                        </td>
                        <td>
                          <Button
                            color="success me-2"
                            disabled={getEntityLoading}
                            onClick={this.getData}
                          >
                            {searchText}
                          </Button>
                        </td>
                        <td className="float-end">
                          <Button
                            color="primary me-2"
                            disabled={!inventories}
                            onClick={() => this.addData()}
                          >
                            Tambah
                          </Button>
                          <Button
                            color="warning me-2"
                            disabled={!inventories}
                            onClick={this.saveChanging}
                          >
                            Simpan
                          </Button>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td></td>
                      <td></td>
                      <td className="float-end">
                        <br />
                        <SearchComponent
                          keyword={keyword}
                          searchedText={searchedText}
                          keywordHandleSubmit={this.filterHandleSubmit}
                          handleChange={this.handleChangeText}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th width="5%">No</th>
                      <th width="10%">Kode Persediaan</th>
                      <th>Nama Persediaan</th>
                      <th width="10%">Unit Pengukuran</th>
                      <th width="20%">Kategori Persediaan</th>
                      <th width="20%">Akun Persediaan</th>
                      <th width="5%" className="text-center">
                        *
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!getSpecificAccountLoading &&
                    !getMeasurementLoading &&
                    !getInventoryCategoryLoading &&
                    !deleteInventoryLoading &&
                    !editInventoryLoading &&
                    inventories &&
                    inventories.length > 0 ? (
                      inventories
                        .filter((inventory) =>
                          inventory.code
                            .toLowerCase()
                            .includes(searchedText.toLowerCase()) ||
                          inventory.name
                            .toLowerCase()
                            .includes(searchedText.toLowerCase())
                            ? true
                            : false
                        )
                        .slice(
                          currentPage * pageSize,
                          (currentPage + 1) * pageSize
                        )
                        .map((inventory, index) => (
                          <tr key={index}>
                            <td className="align-middle">{index + 1}</td>
                            <td>
                              <Input
                                type="text"
                                value={inventory.code}
                                name="code"
                                autoComplete="off"
                                onChange={(event) =>
                                  this.handleChange(event, index)
                                }
                              />
                            </td>
                            <td>
                              <Input
                                type="text"
                                value={inventory.name}
                                name="name"
                                autoComplete="off"
                                onChange={(event) =>
                                  this.handleChange(event, index)
                                }
                              />
                            </td>
                            <td>
                              <Input
                                type="select"
                                name="id_measurement"
                                onChange={(event) =>
                                  this.handleChange(event, index)
                                }
                                value={inventory.id_measurement}
                                options={getMeasurementResult.data}
                                style={{ display: "block", margin: "auto" }}
                              >
                                <option value="">--Pilih Pengukuran--</option>
                                {getMeasurementResult &&
                                  getMeasurementResult.data.map(
                                    (measurement, index) => (
                                      <option
                                        key={index}
                                        value={measurement.id}
                                      >
                                        {measurement.name}
                                      </option>
                                    )
                                  )}
                              </Input>
                            </td>
                            <td>
                              <Input
                                type="select"
                                name="id_inventory_category"
                                onChange={(event) =>
                                  this.handleChange(event, index)
                                }
                                value={inventory.id_inventory_category}
                                options={getInventoryCategoryResult.data}
                                style={{ display: "block", margin: "auto" }}
                              >
                                <option value="">--Pilih Kategori--</option>
                                {getInventoryCategoryResult &&
                                  getInventoryCategoryResult.data.map(
                                    (inventoryCategory, index) => (
                                      <option
                                        key={index}
                                        value={inventoryCategory.id}
                                      >
                                        {inventoryCategory.name}
                                      </option>
                                    )
                                  )}
                              </Input>
                            </td>
                            <td>
                              <Input
                                type="select"
                                name="id_chart_of_account"
                                onChange={(event) =>
                                  this.handleChange(event, index)
                                }
                                value={inventory.id_chart_of_account}
                                options={getSpecificAccountResult.data}
                                style={{ display: "block", margin: "auto" }}
                              >
                                <option value="">--Pilih Akun--</option>
                                {getSpecificAccountResult &&
                                  getSpecificAccountResult.data.map(
                                    (inventoryAccount, index) => (
                                      <option
                                        key={index}
                                        value={inventoryAccount.id}
                                      >
                                        {inventoryAccount.name}
                                      </option>
                                    )
                                  )}
                              </Input>
                            </td>
                            <td className="text-center">
                              <Button
                                color="danger"
                                onClick={() =>
                                  this.deleteData(inventory.id, index)
                                }
                              >
                                X
                              </Button>
                            </td>
                          </tr>
                        ))
                    ) : getSpecificAccountLoading ||
                      getMeasurementLoading ||
                      getInventoryCategoryLoading ||
                      getInventoryLoading ||
                      editInventoryLoading ||
                      deleteInventoryLoading ? (
                      <SpinnerTable colSpan={7} />
                    ) : (
                      <EmptyData colSpan={7} />
                    )}
                  </tbody>
                </Table>
                {!searchedText && (
                  <>
                    <br />
                    <PaginationComponent
                      currentPage={currentPage}
                      pagesCount={pagesCount}
                      totalData={totalData}
                      pageSize={pageSize}
                      handleClick={this.handleClick}
                    />
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  getEntityLoading: state.EntityReducer.getEntityLoading,
  getEntityResult: state.EntityReducer.getEntityResult,
  getEntityError: state.EntityReducer.getEntityError,

  getInventoryLoading: state.InventoryReducer.getInventoryLoading,
  getInventoryResult: state.InventoryReducer.getInventoryResult,
  getInventoryError: state.InventoryReducer.getInventoryError,

  getInventoryCategoryLoading:
    state.InventoryCategoryReducer.getInventoryCategoryLoading,
  getInventoryCategoryResult:
    state.InventoryCategoryReducer.getInventoryCategoryResult,
  getInventoryCategoryError:
    state.InventoryCategoryReducer.getInventoryCategoryError,

  getMeasurementLoading: state.MeasurementReducer.getMeasurementLoading,
  getMeasurementResult: state.MeasurementReducer.getMeasurementResult,
  getMeasurementError: state.MeasurementReducer.getMeasurementError,

  getSpecificAccountLoading: state.AccountReducer.getSpecificAccountLoading,
  getSpecificAccountResult: state.AccountReducer.getSpecificAccountResult,
  getSpecificAccountError: state.AccountReducer.getSpecificAccountError,

  editInventoryLoading: state.InventoryReducer.editInventoryLoading,
  editInventoryResult: state.InventoryReducer.editInventoryResult,
  editInventoryError: state.InventoryReducer.editInventoryError,

  deleteInventoryLoading: state.InventoryReducer.deleteInventoryLoading,
  deleteInventoryResult: state.InventoryReducer.deleteInventoryResult,
  deleteInventoryError: state.InventoryReducer.deleteInventoryError,
});

export default connect(mapStateToProps, null)(InventoryList);
