import {
  dispatchError,
  dispatchLoading,
  dispatchSuccess,
  getAuthorizationHeader,
} from "../utils";
import axios from "axios";

export const GET_INVENTORY_LIST = "GET_INVENTORY_LIST";
export const EDIT_INVENTORY = "EDIT_INVENTORY";
export const DELETE_INVENTORY = "DELETE_INVENTORY";

export const getInventoryList = (idEntity) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_INVENTORY_LIST);
    axios({
      method: "GET",
      url: "inventory?id_entity=" + idEntity,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, GET_INVENTORY_LIST, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, GET_INVENTORY_LIST, error.response.data);
      });
  };
};

export const editInventory = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, EDIT_INVENTORY);
    axios({
      method: "PUT",
      url: "inventory",
      data,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, EDIT_INVENTORY, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, EDIT_INVENTORY, error.response.data);
      });
  };
};
export const deleteInventory = (id) => {
  return (dispatch) => {
    dispatchLoading(dispatch, DELETE_INVENTORY);
    axios({
      method: "DELETE",
      url: "inventory/" + id,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, DELETE_INVENTORY, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, DELETE_INVENTORY, error.response.data);
      });
  };
};
