import React from "react";
import { useSelector } from "react-redux";
import { Button, Input, Table } from "reactstrap";
import Modal from "react-bootstrap/Modal";
import { numberWithCommas } from "../../../utils";
import SpinnerTable from "../../spinner/SpinnerTable";

export const AddDispositionModal = ({
  modalToggle,
  handleClose,
  handleSubmit,
  handleChange,
  selectedFixedAsset,
}) => {
  const { getDispositionFixedAssetResult, addDispositionFixedAssetLoading } =
    useSelector((state) => state.FixedAssetReducer);

  return (
    <Modal show={modalToggle} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Disposisi Aset Tetap</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table responsive>
          <tbody>
            {addDispositionFixedAssetLoading ? (
              <SpinnerTable colSpan={2} />
            ) : (
              <>
                <tr>
                  <th width="40%">Field</th>
                  <th>Data</th>
                </tr>
                <tr>
                  <td className="align-middle">Tanggal</td>
                  <td>
                    <Input
                      type="date"
                      value={
                        selectedFixedAsset && selectedFixedAsset.dispositionDate
                          ? selectedFixedAsset.dispositionDate
                          : ""
                      }
                      name="dispositionDate"
                      autoComplete="off"
                      onChange={(event) => handleChange(event)}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="align-middle">Nomor Aset</td>
                  <td>
                    <Input
                      type="select"
                      name="id"
                      onChange={(event) => handleChange(event)}
                      value={
                        selectedFixedAsset && selectedFixedAsset.id
                          ? selectedFixedAsset.id
                          : "0"
                      }
                      options={getDispositionFixedAssetResult.data}
                      style={{ display: "block", margin: "auto" }}
                    >
                      <option value="">--Pilih Aset--</option>
                      {getDispositionFixedAssetResult &&
                        getDispositionFixedAssetResult.data.map(
                          (asset, index) => (
                            <option key={index} value={asset.id}>
                              {asset.code}
                            </option>
                          )
                        )}
                    </Input>
                  </td>
                </tr>
                <tr>
                  <td>Nama Aset</td>
                  <td>{selectedFixedAsset.name}</td>
                </tr>
                <tr>
                  <td>Nilai Perolehan</td>
                  <td>
                    {selectedFixedAsset.acquisition_value
                      ? numberWithCommas(selectedFixedAsset.acquisition_value)
                      : "0"}
                  </td>
                </tr>
                <tr>
                  <td>Akumulasi Depresiasi</td>
                  <td>
                    {selectedFixedAsset.economicAge &&
                    selectedFixedAsset.acquisition_value &&
                    parseInt(selectedFixedAsset.economicAge) > 0
                      ? numberWithCommas(
                          selectedFixedAsset.depreciation_accumulation_temp
                        )
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td>Rugi Disposisi</td>
                  <td>
                    {selectedFixedAsset.economicAge &&
                    selectedFixedAsset.acquisition_value &&
                    parseInt(selectedFixedAsset.economicAge) > 0 &&
                    parseInt(selectedFixedAsset.depreciation_loss_temp) >= 0
                      ? numberWithCommas(
                          selectedFixedAsset.depreciation_loss_temp
                        )
                      : "-"}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td className="text-end">
                    <Button color="primary" onClick={handleSubmit}>
                      Simpan
                    </Button>
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </Table>
      </Modal.Body>
    </Modal>
  );
};
