import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import swal from "sweetalert";
import { getEntityList } from "../../../actions/EntityAction";
import {
  deleteMeasurement,
  editMeasurement,
  getMeasurementList,
} from "../../../actions/MeasurementAction";
import SpinnerTable from "../../../components/spinner/SpinnerTable";
import EmptyData from "../../../components/Table/EmptyData";

class MeasurementList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      idEntity: "",
      measurements: false,
      isEditing: false,
      searchText: "Cari",
    };
  }

  componentDidMount() {
    this.props.dispatch(getEntityList());
  }

  componentDidUpdate(prevProps) {
    const {
      getMeasurementResult,
      getMeasurementError,
      editMeasurementResult,
      editMeasurementError,
      deleteMeasurementResult,
      deleteMeasurementError,
    } = this.props;
    if (
      getMeasurementResult &&
      prevProps.getMeasurementResult !== getMeasurementResult
    ) {
      this.setState({
        measurements: getMeasurementResult.data,
        isEditing: false,
      });
    }

    if (
      editMeasurementResult &&
      prevProps.editMeasurementResult !== editMeasurementResult
    ) {
      this.setState({
        isEditing: false,
      });
      this.props.dispatch(getMeasurementList(this.state.idEntity));
      swal("Sukses", editMeasurementResult.messages, "success");
    }

    if (
      deleteMeasurementResult &&
      prevProps.deleteMeasurementResult !== deleteMeasurementResult
    ) {
      this.getData();
      this.setState({
        isEditing: false,
      });
      swal("Sukses", deleteMeasurementResult.messages, "success");
    }

    if (
      getMeasurementError &&
      prevProps.getMeasurementError !== getMeasurementError
    ) {
      swal("Error", getMeasurementError.messages.error, "error");
    }

    if (
      editMeasurementError &&
      prevProps.editMeasurementError !== editMeasurementError
    ) {
      swal("Error", editMeasurementError.messages.error, "error");
    }

    if (
      deleteMeasurementError &&
      prevProps.deleteMeasurementError !== deleteMeasurementError
    ) {
      swal("Error", deleteMeasurementError.messages.error, "error");
    }
  }

  getData = () => {
    const { idEntity } = this.state;
    if (idEntity) {
      this.props.dispatch(getMeasurementList(idEntity));
    }
  };

  handleSelectChange = (event) => {
    const value = event.target.value;
    this.setState({
      [event.target.name]: value,
      searchText: "Cari",
      measurements: false,
    });
  };

  addData = () => {
    const { measurements, idEntity } = this.state;
    const template = {
      id: "",
      id_entity: idEntity,
      code: "",
      name: "",
    };

    const measurementsTemp = [...measurements];
    measurementsTemp.push(template);

    this.setState({
      measurements: measurementsTemp.sort((a, b) =>
        a.code.localeCompare(b.code)
      ),
    });
  };

  saveChanging = () => {
    const { measurements, isEditing } = this.state;

    if (isEditing) this.props.dispatch(editMeasurement(measurements));
    else swal("Error", "Tidak ada data yang harus disimpan!!", "error");
  };

  handleChange = (event, index) => {
    const { measurements } = this.state;
    const updatedCategories = [...measurements];
    updatedCategories[index][event.target.name] = event.target.value;

    this.setState({
      measurements: updatedCategories,
      isEditing: true,
    });
  };

  deleteData = (id, index) => {
    const { isEditing, measurements } = this.state;

    if (id) {
      if (isEditing)
        swal(
          "Error",
          "Data sedang diubah, simpan data terlebih dahulu!",
          "error"
        );
      else {
        this.props.dispatch(deleteMeasurement(id));
      }
    } else {
      const deletedData = [...measurements];
      deletedData.splice(index, 1);
      this.setState({ measurements: deletedData });
    }
  };

  render() {
    const { idEntity, searchText, measurements } = this.state;
    const {
      getEntityLoading,
      getEntityResult,
      getMeasurementLoading,
      deleteMeasurementLoading,
      editMeasurementLoading,
    } = this.props;

    return (
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Master Kategori Persediaan</CardTitle>
              </CardHeader>
              <CardBody>
                <Table borderless>
                  <tbody>
                    {getEntityLoading ? (
                      <tr>
                        <td className="text-center">
                          <Spinner color="primary" />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td width="20%">
                          <Input
                            type="select"
                            name="idEntity"
                            onChange={(event) => this.handleSelectChange(event)}
                            value={idEntity}
                            options={getEntityResult.data}
                            style={{ display: "block", margin: "auto" }}
                          >
                            <option value="">--Pilih Entitas--</option>
                            {getEntityResult &&
                              getEntityResult.data.map((entity, index) => (
                                <option key={index} value={entity.id}>
                                  {entity.entity_name}
                                </option>
                              ))}
                          </Input>
                        </td>
                        <td>
                          <Button
                            color="success me-2"
                            disabled={getEntityLoading}
                            onClick={this.getData}
                          >
                            {searchText}
                          </Button>
                        </td>
                        <td className="float-end">
                          <Button
                            color="primary me-2"
                            disabled={!measurements}
                            onClick={() => this.addData()}
                          >
                            Tambah
                          </Button>
                          <Button
                            color="warning me-2"
                            disabled={!measurements}
                            onClick={this.saveChanging}
                          >
                            Simpan
                          </Button>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th width="5%">No</th>
                      <th width="30%">Kode</th>
                      <th>Kategory</th>
                      <th width="5%" className="text-center">
                        *
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {!deleteMeasurementLoading &&
                    !editMeasurementLoading &&
                    measurements &&
                    measurements.length > 0 ? (
                      measurements.map((measurement, index) => (
                        <tr key={index}>
                          <td className="align-middle">{index + 1}</td>
                          <td>
                            <Input
                              type="text"
                              value={measurement.code}
                              name="code"
                              autoComplete="off"
                              onChange={(event) =>
                                this.handleChange(event, index)
                              }
                            />
                          </td>
                          <td>
                            <Input
                              type="text"
                              value={measurement.name}
                              name="name"
                              autoComplete="off"
                              onChange={(event) =>
                                this.handleChange(event, index)
                              }
                            />
                          </td>
                          <td className="text-center">
                            <Button
                              color="danger"
                              onClick={() =>
                                this.deleteData(measurement.id, index)
                              }
                            >
                              X
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : getMeasurementLoading ||
                      editMeasurementLoading ||
                      deleteMeasurementLoading ? (
                      <SpinnerTable colSpan={4} />
                    ) : (
                      <EmptyData colSpan={4} />
                    )}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  getEntityLoading: state.EntityReducer.getEntityLoading,
  getEntityResult: state.EntityReducer.getEntityResult,
  getEntityError: state.EntityReducer.getEntityError,

  getMeasurementLoading: state.MeasurementReducer.getMeasurementLoading,
  getMeasurementResult: state.MeasurementReducer.getMeasurementResult,
  getMeasurementError: state.MeasurementReducer.getMeasurementError,

  editMeasurementLoading: state.MeasurementReducer.editMeasurementLoading,
  editMeasurementResult: state.MeasurementReducer.editMeasurementResult,
  editMeasurementError: state.MeasurementReducer.editMeasurementError,

  deleteMeasurementLoading: state.MeasurementReducer.deleteMeasurementLoading,
  deleteMeasurementResult: state.MeasurementReducer.deleteMeasurementResult,
  deleteMeasurementError: state.MeasurementReducer.deleteMeasurementError,
});

export default connect(mapStateToProps, null)(MeasurementList);
