import {
  asyncLocalStorage,
  dispatchError,
  dispatchLoading,
  dispatchSuccess,
  getAuthorizationHeader,
} from "../utils";
import axios from "axios";

export const LOGIN_USER = "LOGIN_USER";
export const GET_ME = "GET_ME";

export const loginUser = (username, password) => {
  return (dispatch) => {
    dispatchLoading(dispatch, LOGIN_USER);

    axios({
      method: "POST",
      url: "login",
      timeout: 120000,
      data: {
        username,
        password,
      },
    })
      .then((response) => {
        // axiosInstance.defaults.withCredentials = true;
        // axiosInstance.defaults.headers = {
        //   "Content-Type": "application/json",
        //   Authorization: response.data.data.token,
        // };
        dispatchSuccess(dispatch, LOGIN_USER, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, LOGIN_USER, error.response.data);
      });
  };
};

export const getMe = () => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_ME);

    axios({
      method: "GET",
      url: "me",
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, GET_ME, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, GET_ME, error.response.data);
      });
  };
};

export const logoutUser = (navigate) => {
  asyncLocalStorage.removeItem("user").then(() => {
    navigate("/login");
  });
};
