import {
  dispatchError,
  dispatchLoading,
  dispatchSuccess,
  getAuthorizationHeader,
} from "../utils";
import axios from "axios";

export const GET_INVENTORY_CATEGORY_LIST = "GET_INVENTORY_CATEGORY_LIST";
export const EDIT_INVENTORY_CATEGORY = "EDIT_INVENTORY_CATEGORY";
export const ADD_INVENTORY_CATEGORY = "ADD_INVENTORY_CATEGORY";
export const DELETE_INVENTORY_CATEGORY = "DELETE_INVENTORY_CATEGORY";

export const getInventoryCategoryList = (idEntity) => {
  return (dispatch) => {
    dispatchLoading(dispatch, GET_INVENTORY_CATEGORY_LIST);
    axios({
      method: "GET",
      url: "inventory-category?id_entity=" + idEntity,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, GET_INVENTORY_CATEGORY_LIST, response.data);
      })
      .catch((error) => {
        dispatchError(
          dispatch,
          GET_INVENTORY_CATEGORY_LIST,
          error.response.data
        );
      });
  };
};

export const editInventoryCategory = (data) => {
  return (dispatch) => {
    dispatchLoading(dispatch, EDIT_INVENTORY_CATEGORY);
    axios({
      method: "PUT",
      url: "inventory-category",
      data,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, EDIT_INVENTORY_CATEGORY, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, EDIT_INVENTORY_CATEGORY, error.response.data);
      });
  };
};
export const deleteInventoryCategory = (id) => {
  return (dispatch) => {
    dispatchLoading(dispatch, DELETE_INVENTORY_CATEGORY);
    axios({
      method: "DELETE",
      url: "inventory-category/" + id,
      timeout: 120000,
      headers: getAuthorizationHeader(),
    })
      .then((response) => {
        dispatchSuccess(dispatch, DELETE_INVENTORY_CATEGORY, response.data);
      })
      .catch((error) => {
        dispatchError(dispatch, DELETE_INVENTORY_CATEGORY, error.response.data);
      });
  };
};
