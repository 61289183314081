import {
  ADD_INVENTORY_MUTATION,
  GET_INVENTORY_MUTATION_LIST,
  GET_INVENTORY_MUTATION_ALL_LIST,
} from "../../actions/InventoryMutationAction";

const initialState = {
  getInventoryMutationLoading: false,
  getInventoryMutationResult: false,
  getInventoryMutationError: false,

  getInventoryMutationAllLoading: false,
  getInventoryMutationAllResult: false,
  getInventoryMutationAllError: false,

  addInventoryMutationLoading: false,
  addInventoryMutationResult: false,
  addInventoryMutationError: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case GET_INVENTORY_MUTATION_LIST:
      return {
        ...state,
        getInventoryMutationLoading: action.payload.loading,
        getInventoryMutationResult: action.payload.data,
        getInventoryMutationError: action.payload.errorMessage,
      };
    case GET_INVENTORY_MUTATION_ALL_LIST:
      return {
        ...state,
        getInventoryMutationAllLoading: action.payload.loading,
        getInventoryMutationAllResult: action.payload.data,
        getInventoryMutationAllError: action.payload.errorMessage,
      };
    case ADD_INVENTORY_MUTATION:
      return {
        ...state,
        addInventoryMutationLoading: action.payload.loading,
        addInventoryMutationResult: action.payload.data,
        addInventoryMutationError: action.payload.errorMessage,
      };
    default:
      return state;
  }
}
