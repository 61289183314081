// export const BASE_URL = "http://localhost/bumdes_api/public/";
export const BASE_URL = "https://bumdes-api.warastra-adhiguna.com/";
export const API_URL = BASE_URL + "api/";
export const ACTIVE_USER = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user"))
  : "";

// export const TOKEN = localStorage.getItem("token")
//   ? localStorage.getItem("token")
//   : "";

export const DEFAULT_TABLE_PAGE = 0;
export const DEFAULT_TABLE_ROW_PER_PAGE = 10;
export const MONTH_LIST = [
  { id: "1", name: "Januari" },
  { id: "2", name: "Februari" },
  { id: "3", name: "Maret" },
  { id: "4", name: "April" },
  { id: "5", name: "Mei" },
  { id: "6", name: "Juni" },
  { id: "7", name: "Juli" },
  { id: "8", name: "Agustus" },
  { id: "9", name: "September" },
  { id: "10", name: "Oktober" },
  { id: "11", name: "November" },
  { id: "12", name: "Desember" },
];

export const getYearList = () => {
  let years = [];

  for (let i = 1950; i < 2100; i++) {
    years.push(i);
  }

  return years;
};
